import React, { useState, useEffect } from "react";
import * as Material from "@mui/material";
import {
  EmploymentStatusSave,
  getSkills,
  getEmploymentStatuses,
  getWorkExperiencebyProfileID,
  clientWorkExperienceSave,
  saveJobPreference,
  getJobPreference,
} from "../../../services/ClientProfileService";
import { displayAlert } from "../../common/Alert";
import LoadingSpinner from "../../common/LoadingSpinner";
import { useSelector } from "react-redux";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
const useWorkExperienceData = (initialData = {}) => {
  const user_id = useSelector((state) => state.user.id);
  const profiles_id = useSelector((state) => state.profile_id || 0);

  const createNewWorkExpData = () => ({
    profiles_id: profiles_id,
    company_name: "",
    position: "",
    status: "",
    no_of_months: "",
    address: "",
    added_by: user_id,
  });

  const workExpData = initialData
    ? initialData?.result1.map((workExp) => {
        return {
          id: workExp?.id || 0,
          profiles_id: workExp.profiles_id,
          company_name: workExp.company_name || "",
          position: workExp.position || "",
          status: workExp.status || "",
          no_of_months: workExp.no_of_months || "",
          address: workExp.address || "",
          added_by: workExp.added_by || user_id,
        };
      })
    : [createNewWorkExpData()];

  return { workExpData };
};
const EmploymentStatusContent = ({ InitialProfileData }) => {
  const initialStateData = {
    id: 0,
    employment_status: "",
    employed_type: "",
    self_employed_status: "",
    unemployed_status: "",
    other_skills: "",
    is_ofw: 0,
    ofw_country: "",
    is_former_ofw: 0,
    latest_country_of_deployment: "",
    return_year: 0,
    is_4ps: 0,
    household_id: 0,
  };

  const profiles_id = useSelector((state) => state.profile_id);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const editMode =
    InitialProfileData && Object.keys(InitialProfileData).length > 0;
  const [employmentStatus, setEmploymentStatus] = useState(initialStateData);
  const [childEmploymentStatus, setChildEmploymentStatus] = useState("");
  const [selfEmploymentStatus, setSelfEmploymentStatus] = useState([]);
  const [UnemployedStatus, setUnemployedStatus] = useState([]);
  const [employmentStatusOptions, setEmploymentStatusOptions] = useState([]);
  const [otherSelfEmploymentText, setOtherSelfEmploymentText] = useState("");
  const [otherUnemployedText, setOtherUnemployedText] = useState("");
  const [country, setLaidOffCountry] = useState("");
  const [others, setOthers] = useState("");

  const [skillsList, setSkillsList] = useState([]);
  const [otherSkills, setOtherSkills] = useState([]);

  const [rowExp, setRowExp] = useState([{ id: 1 }]);
  const [experience, setExp] = useState();
  const [initialSave, setInitialSave] = useState(false);

  const [occupationList, setOccupationList] = useState([]);
  const [localList, setLocalList] = useState([]);
  const [overseasList, setOverseasList] = useState([]);
  const [occupationInput, setOccupationInput] = useState("");
  const [localInput, setLocalInput] = useState("");
  const [overseasInput, setOverseasInput] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [editingListType, setEditingListType] = useState("");
  const [preferredOccupationType, setPreferredOccupationType] = useState("");
  const [preferredWorkLocation, setPreferredWorkLocation] = useState("");
  const [jobPreference, setJobPreference] = useState([]);

  // const profile_id = useSelector((state) => state.profile_id);
  const prof_id = editMode ? InitialProfileData.result0[0].id : profiles_id;
  const user_id = useSelector((state) => state.user.id);
  // const user_id = 123;

  useEffect(() => {
    if (editMode) {
      setEmploymentStatus((prevState) => ({
        ...prevState,
        ...InitialProfileData.result0[0],
      }));
      setOtherSkills(
        InitialProfileData.result0[0].other_skills
          ? InitialProfileData?.result0[0]?.other_skills?.split(",")
          : []
      );
      setChildEmploymentStatus(
        InitialProfileData.result0[0].employed_type || ""
      );

      const selfEmpStatus = employmentStatusOptions.find(
        (status) =>
          status.occupation ===
          InitialProfileData.result0[0].self_employed_status
      );

      if (selfEmpStatus) {
        setSelfEmploymentStatus(selfEmpStatus.occupation);
        setOtherSelfEmploymentText("");
      } else {
        // setSelfEmploymentStatus("others");
        let newStatusArray =
          InitialProfileData?.result0[0]?.self_employed_status?.split(","); // for employed status

        setSelfEmploymentStatus(newStatusArray);
        setUnemployedStatus(
          InitialProfileData?.result0[0]?.unemployed_status?.split(",")
        );
        setOtherSelfEmploymentText(
          InitialProfileData.result0[0].self_employed_status || ""
        );
        setOthers(InitialProfileData.result0[0].other_jobs);
        setLaidOffCountry(InitialProfileData.result0[0].abroad_country);
      }

      const unemployStatus = employmentStatusOptions.find(
        (status) =>
          status.occupation === InitialProfileData.result0[0].unemployed_status
      );
      if (unemployStatus) {
        setUnemployedStatus(unemployStatus.occupation);
        setOtherUnemployedText("");
      } else {
        // setUnemployedStatus("others");
        setOtherUnemployedText(
          InitialProfileData.result0[0].unemployed_status || ""
        );
      }
    }
  }, [InitialProfileData, editMode, employmentStatusOptions]);

  useEffect(() => {
    fetchStatuses();
    fetchSkills();
    fetchWorkExp();
    fetchJobPreference();
  }, []);

  const fetchWorkExp = async () => {
    try {
      const data = await getWorkExperiencebyProfileID(prof_id);

      if (data && !data.error) {
        setExp(data.workExpData);
      } else {
        console.error(
          "Error fetching employment statuses:",
          data?.message || "Unknown error"
        );
      }
    } catch (error) {
      console.error(
        "Error fetching statuses:",
        error?.message || "Unknown error"
      );
    }
  };

  const fetchStatuses = async () => {
    try {
      const data = await getEmploymentStatuses();
      // console.log("$$dataEmploymentStatuses", data);
      if (data && !data.error) {
        setEmploymentStatusOptions(data);
      } else {
        console.error("Error fetching employment statuses:", data.message);
      }
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const data = await getSkills();
      if (data && !data.error) {
        setSkillsList(data);
      } else {
        console.error("Error fetching skills:", data.message);
      }
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

  const fetchJobPreference = async () => {
    try {
      const data = await getJobPreference(prof_id);
      if (data && !data.error) {
        const occupationData = data.jobPreferenceData.pref_occupation || "";
        const localData = data.jobPreferenceData.pref_local_location || "";
        const overseasData = data.jobPreferenceData.pref_overseas_country || "";
        setJobPreference(data);
        setPreferredOccupationType(
          data.jobPreferenceData.pref_occupation_type || ""
        );
        setPreferredWorkLocation(
          data.jobPreferenceData.pref_work_location || ""
        );
        setOccupationList(
          occupationData
            ? occupationData.split(",").map((item) => item.trim())
            : []
        );
        setLocalList(
          localData ? localData.split(",").map((item) => item.trim()) : []
        );
        setOverseasList(
          overseasData ? overseasData.split(",").map((item) => item.trim()) : []
        );
      } else {
        console.error("Error fetching job preference:", data.message);
      }
    } catch (error) {
      console.error("Error fetching job preference:", error);
    }
  };

  const handleAddOccupation = () => {
    if (occupationInput.trim() !== "") {
      setOccupationList([...occupationList, occupationInput]);
      setOccupationInput("");
    }
  };

  const handleAddLocal = () => {
    if (localInput.trim() !== "") {
      setLocalList([...localList, localInput]);
      setLocalInput("");
    }
  };

  const handleAddOverseas = () => {
    if (overseasInput.trim() !== "") {
      setOverseasList([...overseasList, overseasInput]);
      setOverseasInput("");
    }
  };

  const handleDoubleClick = (index, listType) => {
    setEditingIndex(index);
    setEditingListType(listType);

    if (listType === "occupation") setEditingValue(occupationList[index]);
    if (listType === "local") setEditingValue(localList[index]);
    if (listType === "overseas") setEditingValue(overseasList[index]);
  };

  const handleSaveEdit = (index) => {
    if (editingValue.trim() === "") return;

    if (editingListType === "occupation") {
      const newList = [...occupationList];
      newList[index] = editingValue;
      setOccupationList(newList);
    }
    if (editingListType === "local") {
      const newList = [...localList];
      newList[index] = editingValue;
      setLocalList(newList);
    }
    if (editingListType === "overseas") {
      const newList = [...overseasList];
      newList[index] = editingValue;
      setOverseasList(newList);
    }
    setEditingIndex(null);
    setEditingListType("");
    setEditingValue("");
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setEditingListType("");
    setEditingValue("");
  };

  const handleDelete = (index, listType) => {
    if (listType === "occupation") {
      setOccupationList((prev) => prev.filter((_, i) => i !== index));
    } else if (listType === "local") {
      setLocalList((prev) => prev.filter((_, i) => i !== index));
    } else if (listType === "overseas") {
      setOverseasList((prev) => prev.filter((_, i) => i !== index));
    }
  };
  const handleOccupationTypeChange = (event) => {
    setPreferredOccupationType(event.target.value);
  };

  const handleWorkLocationChange = (event) => {
    setPreferredWorkLocation(event.target.value);
  };

  const handleChangeStatus = (newStatus) => {
    // const { name, value } = event.target;
    setEmploymentStatus((prevStatus) => ({
      ...prevStatus,
      employment_status:
        prevStatus.employment_status === newStatus ? "" : newStatus,
    }));
    setChildEmploymentStatus("");
    setSelfEmploymentStatus("");
    setUnemployedStatus("");
    setOtherSelfEmploymentText("");
    setOtherUnemployedText("");
    setOthers("");
    setLaidOffCountry("");
  };

  const handleChangeChildStatus = (event) => {
    const value = event.target.value;
    setChildEmploymentStatus(value);
    if (value !== "Self Employed") {
      setSelfEmploymentStatus("");
    }
  };
  // Handle changes to self-employment status checkboxes
  const handleChangeChildSelfStatus = (event, occupation) => {
    const isChecked = event?.target?.checked;
    setSelfEmploymentStatus((prev) => {
      if (isChecked) {
        // Add the occupation to the list if checked
        return [...prev, occupation];
      } else {
        // Remove the occupation if unchecked
        return prev.filter((status) => status !== occupation);
      }
    });
  };

  // Checkbox change handler specifically for "Others"
  const handleChangeOthers = (event) => {
    const isChecked = event.target.checked;
    setSelfEmploymentStatus((prev) => {
      if (isChecked) {
        return [...prev, "others"];
      } else {
        return prev.filter((status) => status !== "others");
      }
    });
  };

  const handleChangeChildUnemployedStatus = (occupation) => {
    setUnemployedStatus((prev) => {
      if (prev.includes(occupation)) {
        // If already selected, remove it
        return prev.filter((item) => item !== occupation);
      } else {
        // Otherwise, add it
        return [...prev, occupation];
      }
    });
  };

  const handleAddRow = () => {
    const newId = rowExp.length > 0 ? rowExp[rowExp.length - 1].id + 1 : 1;
    setRowExp([...rowExp, { id: newId }]);

    setExp((prevExp) => [
      ...(prevExp || []),
      {
        id: 0,
        profiles_id: prof_id,
        company_name: "",
        address: "",
        position: "",
        no_of_months: "",
        status: "",
        added_by: user_id,
      },
    ]);
  };

  const handleRemoveRow = () => {
    const updatedRow = [...rowExp];
    updatedRow.pop();
    setRowExp(updatedRow);
    setExp((prevExp) => prevExp.slice(0, -1));
  };

  const handleChangeWorkExp = (e, index) => {
    const { name, value } = e.target;
    const updatedExp = [...experience];
    updatedExp[index] = { ...updatedExp[index], [name]: value };
    setExp(updatedExp);
  };

  const handleSkillChange = (event) => {
    const skillId = event.target.value;
    setOtherSkills((prevSkills) => {
      const updatedSkills = prevSkills.includes(skillId)
        ? prevSkills.filter((id) => id !== skillId)
        : [...prevSkills, skillId];
      return updatedSkills;
    });
  };

  const validateEmploymentStatus = () => {
    const errors = {};

    if (!employmentStatus.employment_status) {
      errors.employment_status = "Employment status is required.";
    }

    if (
      employmentStatus.employment_status === "Employed" &&
      !childEmploymentStatus
    ) {
      errors.child_employment_status = "Employment type is required.";
    }

    // if (
    //   employmentStatus.employment_status === "Employed" &&
    //   childEmploymentStatus === "Self Employed" &&
    //   !selfEmploymentStatus
    // ) {
    //   errors.self_employment_status = "Self-employed status is required.";
    // }

    // if (
    //   employmentStatus.employment_status === "Employed" &&
    //   childEmploymentStatus === "Self Employed" &&
    //   selfEmploymentStatus === "others" &&
    //   !otherSelfEmploymentText
    // ) {
    //   errors.other_self_employment = "Please specify self-employment.";
    // }

    // if (
    //   employmentStatus.employment_status === "Unemployed" &&
    //   !UnemployedStatus
    // ) {
    //   errors.unemployment_status = "Unemployment status is required.";
    // }

    // if (
    //   employmentStatus.employment_status === "Unemployed" &&
    //   UnemployedStatus === "others" &&
    //   !otherUnemployedText
    // ) {
    //   errors.other_unemployed = "Please specify unemployment.";
    // }

    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const handleSubmitEmploymentStatusAndExperience = () => {
    setLoading(true);

    if (validateEmploymentStatus()) {
      displayAlert({
        message: "Please fill in all required Employment Status fields.",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    const employmentStatusWithProfileId = {
      ...employmentStatus,
      id: prof_id || 0,
      other_skills: otherSkills.join(","),
      employed_type: childEmploymentStatus,
      employed_status: employmentStatus.employment_status,
      abroad_country: country,
      self_employed_status:
        selfEmploymentStatus === "others"
          ? otherSelfEmploymentText
          : selfEmploymentStatus,
      unemployed_status:
        employmentStatus.employment_status === "Unemployed"
          ? UnemployedStatus === "others"
            ? otherUnemployedText
            : UnemployedStatus
          : "",
      other_jobs: others,
      is_ofw: employmentStatus.is_ofw,
      ofw_country: employmentStatus.ofw_country,
      is_former_ofw: employmentStatus.is_former_ofw,
      latest_country_of_deployment:
        employmentStatus.latest_country_of_deployment,
      return_year: employmentStatus.return_year,
      is_4ps: employmentStatus.is_4ps,
      household_id: employmentStatus.household_id,
    };

    EmploymentStatusSave(employmentStatusWithProfileId)
      .then((res) => {
        if (res) {
          displayAlert({
            message: "Employment Status saved successfully.",
            severity: "success",
          });

          editMode ? setInitialSave(false) : setInitialSave(true);

          const experiencePayload = experience.map((exp) => ({
            ...exp,
            profiles_id: prof_id,
            added_by: user_id,
          }));

          clientWorkExperienceSave(experiencePayload)
            .then(() => {
              displayAlert({
                message: "Experience saved successfully.",
                severity: "success",
              });

              editMode ? setInitialSave(false) : setInitialSave(true);
              const jobPreferencePayload = {
                id: editMode ? jobPreference.jobPreferenceData?.id || 0 : 0,
                profile_id: prof_id || 0,
                pref_occupation_type: preferredOccupationType,
                pref_occupation: occupationList.join(","),
                pref_work_location: preferredWorkLocation,
                pref_local_location: localList.join(","),
                pref_overseas_country: overseasList.join(","),
                added_by: user_id,
              };
              saveJobPreference(jobPreferencePayload)
                .then(() => {
                  displayAlert({
                    message: "Job Preference saved successfully.",
                    severity: "success",
                  });

                  editMode ? setInitialSave(false) : setInitialSave(true);
                })
                .catch((error) => {
                  displayAlert({
                    message:
                      "Failed to save Job Preference. " + (error.message || ""),
                    severity: "error",
                  });
                  console.error(error);
                });
            })
            .catch((error) => {
              displayAlert({
                message: "Failed to save experience. " + (error.message || ""),
                severity: "error",
              });
              console.error(error);
            });
        } else {
          displayAlert({
            message: "Unexpected response format from server.",
            severity: "error",
          });
        }
      })
      .catch((error) => {
        displayAlert({
          message:
            error.message ||
            "An error occurred while saving Employment Status.",
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRadioChange = (field) => (event) => {
    const value = parseInt(event.target.value);

    if (field === "is_ofw" && value === 0) {
      setEmploymentStatus({
        ...employmentStatus,
        [field]: value,
        ofw_country: "",
      });
    } else if (field === "is_former_ofw" && value === 0) {
      setEmploymentStatus({
        ...employmentStatus,
        [field]: value,
        latest_country_of_deployment: "",
        return_year: "",
      });
    } else if (field === "is_4ps" && value === 0) {
      setEmploymentStatus({
        ...employmentStatus,
        [field]: value,
        household_id: "",
      });
    } else {
      setEmploymentStatus({
        ...employmentStatus,
        [field]: value,
      });
    }
  };

  const handleTextChange = (field) => (event) => {
    setEmploymentStatus((prevStatus) => ({
      ...prevStatus,
      [field]: event.target.value,
    }));
  };

  return (
    <Material.Box
      sx={{ width: "100%", padding: 2, overflow: "auto", height: "90%" }}
    >
      <Material.Paper sx={{ padding: 2, marginBottom: 4 }}>
        <Material.Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Work Experience
        </Material.Typography>
        <Material.Grid container spacing={2}>
          {experience?.map((workExp, index) => (
            <Material.Grid
              key={index}
              style={{ paddingTop: 20, paddingLeft: 15 }}
              container
              spacing={2}
            >
              <Material.Grid item xs={12}>
                <Material.Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ color: "#42a5f5", fontWeight: "bold" }}
                >
                  {index + 1}
                </Material.Typography>
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.FormControl fullWidth variant="outlined">
                  <Material.TextField
                    name="company_name"
                    label="Company Name"
                    variant="filled"
                    value={workExp.company_name || ""}
                    onChange={(e) => handleChangeWorkExp(e, index)}
                  />
                </Material.FormControl>
              </Material.Grid>
              <Material.Grid item xs={12} sm={6}>
                <Material.FormControl fullWidth variant="outlined">
                  <Material.TextField
                    name="address"
                    label="Address"
                    variant="filled"
                    value={workExp.address || ""}
                    onChange={(e) => handleChangeWorkExp(e, index)}
                  />
                </Material.FormControl>
              </Material.Grid>
              <Material.Grid item xs={12} sm={4}>
                <Material.FormControl fullWidth variant="outlined">
                  <Material.TextField
                    name="position"
                    label="Position"
                    variant="filled"
                    value={workExp.position || ""}
                    onChange={(e) => handleChangeWorkExp(e, index)}
                  />
                </Material.FormControl>
              </Material.Grid>
              <Material.Grid item xs={12} sm={4}>
                <Material.FormControl fullWidth variant="outlined">
                  <Material.TextField
                    name="status"
                    label="Status"
                    variant="filled"
                    value={workExp.status || ""}
                    onChange={(e) => handleChangeWorkExp(e, index)}
                  />
                </Material.FormControl>
              </Material.Grid>
              <Material.Grid item xs={12} sm={4}>
                <Material.FormControl fullWidth variant="outlined">
                  <Material.TextField
                    name="no_of_months"
                    label="Number of Months"
                    variant="filled"
                    value={workExp.no_of_months || ""}
                    onChange={(e) => handleChangeWorkExp(e, index)}
                  />
                </Material.FormControl>
              </Material.Grid>
            </Material.Grid>
          ))}
        </Material.Grid>

        <Material.Grid container spacing={2} sx={{ padding: 2 }}>
          <Material.Grid item xs={12} sm={2}>
            <Material.FormControl fullWidth variant="outlined">
              <Material.Button
                variant="text"
                startIcon={<AddRoundedIcon />}
                onClick={handleAddRow}
              >
                Add Row
              </Material.Button>
            </Material.FormControl>
          </Material.Grid>
          {rowExp.length > 1 ? (
            <Material.Grid item xs={12} sm={3}>
              <Material.FormControl fullWidth variant="outlined">
                <Material.Button
                  variant="text"
                  startIcon={<RemoveRoundedIcon />}
                  onClick={handleRemoveRow}
                >
                  Remove Row
                </Material.Button>
              </Material.FormControl>
            </Material.Grid>
          ) : null}
        </Material.Grid>
      </Material.Paper>

      <Material.Paper sx={{ padding: 2, marginBottom: 4 }}>
        <Material.Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Employment Status/Type
        </Material.Typography>
        <Material.Grid
          container
          spacing={2}
          style={{ paddingTop: 20, margin: 2 }}
        >
          <Material.Grid container spacing={2} alignItems="center">
            <Material.Grid
              item
              xs={6}
              container
              justifyContent="flex-start"
              direction="column"
            >
              <Material.FormControlLabel
                control={
                  <Material.Checkbox
                    checked={employmentStatus.employment_status === "Employed"}
                    onChange={() => handleChangeStatus("Employed")}
                  />
                }
                label="Employed"
              />
            </Material.Grid>
            <Material.Grid
              item
              xs={6}
              container
              justifyContent="flex-start"
              direction="column"
            >
              <Material.FormControlLabel
                control={
                  <Material.Checkbox
                    checked={
                      employmentStatus.employment_status === "Unemployed"
                    }
                    onChange={() => handleChangeStatus("Unemployed")}
                  />
                }
                label="Unemployed"
              />
            </Material.Grid>
          </Material.Grid>

          {errors.employment_status && (
            <Material.FormHelperText error>
              {errors.employment_status}
            </Material.FormHelperText>
          )}

          {employmentStatus.employment_status === "Employed" && (
            <Material.Grid item xs={6} sm={12}>
              <Material.FormControl
                component="fieldset"
                error={!!errors.child_employment_status}
              >
                <Material.RadioGroup
                  aria-labelledby="child-employment-status-group-label"
                  name="child_employment_status"
                  value={childEmploymentStatus}
                  onChange={handleChangeChildStatus}
                >
                  <Material.FormControlLabel
                    value="Wage Employed"
                    control={<Material.Radio />}
                    label="Wage Employed"
                  />
                  <Material.FormControlLabel
                    value="Self Employed"
                    control={<Material.Radio />}
                    label="Self Employed (Please specify)"
                  />
                </Material.RadioGroup>
                {errors.child_employment_status && (
                  <Material.FormHelperText error>
                    {errors.child_employment_status}
                  </Material.FormHelperText>
                )}
              </Material.FormControl>

              {childEmploymentStatus === "Wage Employed" && (
                <Material.Grid item xs={6} sm={12} margin={2}>
                  <Material.FormControl
                    component="fieldset"
                    error={!!errors.self_employment_status}
                  >
                    <Material.FormLabel component="legend">
                      Select an option
                    </Material.FormLabel>
                    <Material.FormGroup>
                      {(
                        employmentStatusOptions?.filter(
                          (status) =>
                            status.employment_status === "Employed" &&
                            status.employed_type === "Wage Employed"
                        ) ?? []
                      ).map((status) => (
                        <Material.FormControlLabel
                          key={status.id}
                          control={
                            <Material.Checkbox
                              checked={selfEmploymentStatus.includes(
                                status.occupation
                              )}
                              onChange={(e) =>
                                handleChangeChildSelfStatus(
                                  e,
                                  status.occupation
                                )
                              }
                            />
                          }
                          label={status.occupation}
                        />
                      ))}
                      <Material.FormControlLabel
                        control={
                          <Material.Checkbox
                            checked={selfEmploymentStatus.includes("others")}
                            onChange={handleChangeOthers}
                          />
                        }
                        label="Others (Please specify)"
                      />
                      {selfEmploymentStatus.includes("others") && (
                        <Material.TextField
                          name="otherSelfEmploymentText"
                          value={others}
                          label="Specify Others"
                          variant="standard"
                          onChange={(e) => setOthers(e.target.value)}
                        />
                      )}
                    </Material.FormGroup>
                    {errors.self_employment_status && (
                      <Material.FormHelperText error>
                        {errors.self_employment_status}
                      </Material.FormHelperText>
                    )}
                  </Material.FormControl>
                </Material.Grid>
              )}

              {childEmploymentStatus === "Self Employed" && (
                <Material.Grid item xs={6} sm={12} margin={2}>
                  <Material.FormControl
                    component="fieldset"
                    error={!!errors.self_employment_status}
                  >
                    <Material.FormLabel component="legend">
                      Select an option
                    </Material.FormLabel>
                    <Material.FormGroup>
                      {(
                        employmentStatusOptions?.filter(
                          (status) =>
                            status.employment_status === "Employed" &&
                            status.employed_type === "Self Employed"
                        ) ?? []
                      ).map((status) => (
                        <Material.FormControlLabel
                          key={status.id}
                          control={
                            <Material.Checkbox
                              checked={selfEmploymentStatus.includes(
                                status.occupation
                              )}
                              onChange={(e) =>
                                handleChangeChildSelfStatus(
                                  e,
                                  status.occupation
                                )
                              }
                            />
                          }
                          label={status.occupation}
                        />
                      ))}
                      <Material.FormControlLabel
                        control={
                          <Material.Checkbox
                            checked={selfEmploymentStatus.includes("others")}
                            onChange={handleChangeOthers}
                          />
                        }
                        label="Others (Please specify)"
                      />
                      {selfEmploymentStatus.includes("others") && (
                        <Material.TextField
                          name="otherSelfEmploymentText"
                          value={others}
                          label="Specify Others"
                          variant="standard"
                          onChange={(e) => setOthers(e.target.value)}
                        />
                      )}
                    </Material.FormGroup>
                    {errors.self_employment_status && (
                      <Material.FormHelperText error>
                        {errors.self_employment_status}
                      </Material.FormHelperText>
                    )}
                  </Material.FormControl>
                </Material.Grid>
              )}
            </Material.Grid>
          )}

          {employmentStatus.employment_status === "Unemployed" && (
            <Material.Grid container spacing={2}>
              <Material.Grid item xs={6} sm={6}></Material.Grid>
              <Material.Grid item xs={6} sm={6}>
                <Material.FormControl
                  component="fieldset"
                  variant="standard"
                  error={!!errors.unemployment_status}
                >
                  <Material.FormLabel component="legend">
                    Select an option
                  </Material.FormLabel>
                  <Material.FormGroup>
                    {(
                      employmentStatusOptions?.filter(
                        (status) => status.employment_status === "Unemployed"
                      ) ?? []
                    ).map((status) => (
                      <Material.FormControlLabel
                        key={status.id}
                        control={
                          <Material.Checkbox
                            checked={UnemployedStatus.includes(
                              status.occupation
                            )}
                            onChange={() =>
                              handleChangeChildUnemployedStatus(
                                status.occupation
                              )
                            }
                          />
                        }
                        label={status.occupation}
                      />
                    ))}
                    <Material.FormControlLabel
                      control={
                        <Material.Checkbox
                          checked={UnemployedStatus.includes(
                            "Terminated/Laid off (abroad)"
                          )}
                          onChange={() =>
                            handleChangeChildUnemployedStatus(
                              "Terminated/Laid off (abroad)"
                            )
                          }
                        />
                      }
                      label="Terminated/Laid off (abroad)"
                    />
                    {UnemployedStatus.includes(
                      "Terminated/Laid off (abroad)"
                    ) && (
                      <Material.TextField
                        name="country"
                        value={country}
                        label="Specify Country"
                        variant="standard"
                        onChange={(e) => setLaidOffCountry(e.target.value)}
                      />
                    )}
                    <Material.FormControlLabel
                      control={
                        <Material.Checkbox
                          checked={UnemployedStatus.includes("others")}
                          onChange={() =>
                            handleChangeChildUnemployedStatus("others")
                          }
                        />
                      }
                      label="Others, Please specify"
                    />
                    {UnemployedStatus.includes("others") && (
                      <Material.TextField
                        name="otherUnemployedText"
                        value={otherUnemployedText}
                        label="Specify Others"
                        variant="standard"
                        onChange={(e) => setOtherUnemployedText(e.target.value)}
                      />
                    )}
                  </Material.FormGroup>
                  {errors.unemployment_status && (
                    <Material.FormHelperText error>
                      {errors.unemployment_status}
                    </Material.FormHelperText>
                  )}
                </Material.FormControl>
              </Material.Grid>
            </Material.Grid>
          )}
        </Material.Grid>

        <Material.Paper
          sx={{
            backgroundColor: "#5555551a",
            borderRadius: "10px",
            padding: 1,
            boxShadow: 0,
            marginBottom: 2,
          }}
        >
          <Material.Grid container spacing={2} padding={2}>
            {/* Are you an OFW? */}
            <Material.Grid item xs={6}>
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Typography sx={{ marginRight: 2 }}>
                  Are you an OFW?
                </Material.Typography>
                <Material.RadioGroup
                  row
                  value={employmentStatus.is_ofw}
                  onChange={handleRadioChange("is_ofw")}
                >
                  <Material.FormControlLabel
                    value={1}
                    control={<Material.Radio />}
                    label="Yes"
                  />
                  <Material.FormControlLabel
                    value={0}
                    control={<Material.Radio />}
                    label="No"
                  />
                </Material.RadioGroup>
              </Material.Box>
            </Material.Grid>

            {/* Are you a former OFW? */}
            <Material.Grid item xs={6}>
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Typography sx={{ marginRight: 2 }}>
                  Are you a former OFW?
                </Material.Typography>
                <Material.RadioGroup
                  row
                  value={employmentStatus.is_former_ofw}
                  onChange={handleRadioChange("is_former_ofw")}
                >
                  <Material.FormControlLabel
                    value={1}
                    control={<Material.Radio />}
                    label="Yes"
                  />
                  <Material.FormControlLabel
                    value={0}
                    control={<Material.Radio />}
                    label="No"
                  />
                </Material.RadioGroup>
              </Material.Box>
            </Material.Grid>

            {/* Specify Country */}
            <Material.Grid item xs={6}>
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Typography sx={{ marginRight: 2 }}>
                  Specify Country:
                </Material.Typography>
                <Material.TextField
                  variant="standard"
                  value={employmentStatus.ofw_country}
                  disabled={employmentStatus.is_ofw === 0}
                  onChange={handleTextChange("ofw_country")}
                />
              </Material.Box>
            </Material.Grid>

            {/* Latest country of deployment */}
            <Material.Grid item xs={6}>
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Typography sx={{ marginRight: 2 }}>
                  Latest country of deployment:
                </Material.Typography>
                <Material.TextField
                  variant="standard"
                  value={employmentStatus.latest_country_of_deployment}
                  disabled={employmentStatus.is_former_ofw === 0}
                  onChange={handleTextChange("latest_country_of_deployment")}
                />
              </Material.Box>
            </Material.Grid>

            <Material.Grid item xs={6}></Material.Grid>

            {/* Month and year of return to Philippines */}
            <Material.Grid item xs={6}>
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Typography sx={{ marginRight: 2 }}>
                  Month and year of return to Philippines:
                </Material.Typography>
                <Material.TextField
                  variant="standard"
                  value={employmentStatus.return_year}
                  disabled={employmentStatus.is_former_ofw === 0}
                  onChange={handleTextChange("return_year")}
                />
              </Material.Box>
            </Material.Grid>
          </Material.Grid>

          <Material.Divider />

          <Material.Grid container spacing={2} padding={2}>
            <Material.Grid item xs={6}>
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Typography sx={{ marginRight: 2 }}>
                  Are you a 4Ps beneficiary:
                </Material.Typography>
                <Material.RadioGroup
                  row
                  value={employmentStatus.is_4ps}
                  onChange={handleRadioChange("is_4ps")}
                >
                  <Material.FormControlLabel
                    value={1}
                    control={<Material.Radio />}
                    label="Yes"
                  />
                  <Material.FormControlLabel
                    value={0}
                    control={<Material.Radio />}
                    label="No"
                  />
                </Material.RadioGroup>
              </Material.Box>
            </Material.Grid>

            <Material.Grid item xs={6}>
              <Material.Box sx={{ display: "flex", alignItems: "center" }}>
                <Material.Typography sx={{ marginRight: 2 }}>
                  If yes, please provide Household ID No.:
                </Material.Typography>
                <Material.TextField
                  variant="standard"
                  value={employmentStatus.household_id}
                  disabled={employmentStatus.is_4ps === 0}
                  onChange={handleTextChange("household_id")}
                />
              </Material.Box>
            </Material.Grid>
          </Material.Grid>
        </Material.Paper>
      </Material.Paper>

      <Material.Paper sx={{ padding: 2, marginBottom: 4 }}>
        <Material.Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Job Preference
        </Material.Typography>
        <Material.Grid container spacing={2}>
          {/* Preferred Occupation */}
          <Material.Grid item xs={12} sm={4}>
            <Material.FormControl>
              <Material.FormLabel> PREFERRED OCCUPATION </Material.FormLabel>
              <Material.RadioGroup
                row
                name="preferred-occupation-type"
                value={preferredOccupationType}
                onChange={handleOccupationTypeChange}
              >
                <Material.FormControlLabel
                  value="Part-time"
                  control={<Material.Radio />}
                  label="Part-time"
                />
                <Material.FormControlLabel
                  value="Full-time"
                  control={<Material.Radio />}
                  label="Full-time"
                />
              </Material.RadioGroup>
            </Material.FormControl>
          </Material.Grid>

          {/* Preferred Work Location */}
          <Material.Grid item xs={12} sm={8}>
            <Material.FormControl>
              <Material.FormLabel> PREFERRED WORK LOCATION </Material.FormLabel>
              <Material.RadioGroup
                row
                name="preferred-work-location"
                value={preferredWorkLocation}
                onChange={handleWorkLocationChange}
              >
                <Material.FormControlLabel
                  value="Local"
                  control={<Material.Radio />}
                  label="Local (specify cities/municipalities)"
                />
                <Material.FormControlLabel
                  value="Overseas"
                  control={<Material.Radio />}
                  label="Overseas (specify countries)"
                />
              </Material.RadioGroup>
            </Material.FormControl>
          </Material.Grid>
          {/* Occupation List */}
          <Material.Grid item xs={12} sm={4}>
            <Material.Box sx={{ width: 300, margin: "auto" }}>
              <Material.List>
                {occupationList.map((item, index) => (
                  <Material.ListItem
                    key={index}
                    sx={{
                      borderBottom: "1px solid #ccc",
                      py: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onDoubleClick={() => handleDoubleClick(index, "occupation")}
                  >
                    <Material.Typography variant="body1" sx={{ mr: 1 }}>
                      {index + 1}.
                    </Material.Typography>
                    {editingIndex === index &&
                    editingListType === "occupation" ? (
                      <Material.TextField
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        onBlur={() => handleSaveEdit(index)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleSaveEdit(index);
                          if (e.key === "Escape") handleCancelEdit();
                        }}
                        autoFocus
                        size="small"
                        sx={{ flexGrow: 1 }}
                      />
                    ) : (
                      <Material.Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {item}
                      </Material.Typography>
                    )}
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(index, "occupation")}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Material.ListItem>
                ))}
              </Material.List>
              <Material.TextField
                fullWidth
                variant="filled"
                label="Occupation"
                value={occupationInput}
                onChange={(e) => setOccupationInput(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Material.Button
                fullWidth
                variant="text"
                color="primary"
                onClick={handleAddOccupation}
                sx={{ mt: 2 }}
              >
                Add Occupation
              </Material.Button>
            </Material.Box>
          </Material.Grid>

          {/* Local List */}
          <Material.Grid item xs={12} sm={4}>
            <Material.Box sx={{ width: 300, margin: "auto" }}>
              <Material.List>
                {localList.map((item, index) => (
                  <Material.ListItem
                    key={index}
                    sx={{
                      borderBottom: "1px solid #ccc",
                      py: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onDoubleClick={() => handleDoubleClick(index, "local")}
                  >
                    <Material.Typography variant="body1" sx={{ mr: 1 }}>
                      {index + 1}.
                    </Material.Typography>
                    {editingIndex === index && editingListType === "local" ? (
                      <Material.TextField
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        onBlur={() => handleSaveEdit(index)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleSaveEdit(index);
                          if (e.key === "Escape") handleCancelEdit();
                        }}
                        autoFocus
                        size="small"
                        sx={{ flexGrow: 1 }}
                      />
                    ) : (
                      <Material.Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {item}
                      </Material.Typography>
                    )}
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(index, "local")}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Material.ListItem>
                ))}
              </Material.List>
              <Material.TextField
                fullWidth
                variant="filled"
                label="Local"
                value={localInput}
                onChange={(e) => setLocalInput(e.target.value)}
                sx={{ mt: 2 }}
                disabled={preferredWorkLocation === "Overseas"} // Disable input field
              />
              <Material.Button
                fullWidth
                variant="text"
                color="primary"
                onClick={handleAddLocal}
                sx={{ mt: 2 }}
                disabled={preferredWorkLocation === "Overseas"} // Disable button
              >
                Add Local
              </Material.Button>
            </Material.Box>
          </Material.Grid>

          {/* Overseas List */}
          <Material.Grid item xs={12} sm={4}>
            <Material.Box sx={{ width: 300, margin: "auto" }}>
              <Material.List>
                {overseasList.map((item, index) => (
                  <Material.ListItem
                    key={index}
                    sx={{
                      borderBottom: "1px solid #ccc",
                      py: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onDoubleClick={() => handleDoubleClick(index, "overseas")}
                  >
                    <Material.Typography variant="body1" sx={{ mr: 1 }}>
                      {index + 1}.
                    </Material.Typography>
                    {editingIndex === index &&
                    editingListType === "overseas" ? (
                      <Material.TextField
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        onBlur={() => handleSaveEdit(index)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleSaveEdit(index);
                          if (e.key === "Escape") handleCancelEdit();
                        }}
                        autoFocus
                        size="small"
                        sx={{ flexGrow: 1 }}
                      />
                    ) : (
                      <Material.Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {item}
                      </Material.Typography>
                    )}
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelete(index, "overseas")}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Material.ListItem>
                ))}
              </Material.List>
              <Material.TextField
                fullWidth
                variant="filled"
                label="Country"
                value={overseasInput}
                onChange={(e) => setOverseasInput(e.target.value)}
                sx={{ mt: 2 }}
                disabled={preferredWorkLocation === "Local"} // Disable input field
              />
              <Material.Button
                fullWidth
                variant="text"
                color="primary"
                onClick={handleAddOverseas}
                sx={{ mt: 2 }}
                disabled={preferredWorkLocation === "Local"} // Disable button
              >
                Add Overseas
              </Material.Button>
            </Material.Box>
          </Material.Grid>
        </Material.Grid>
      </Material.Paper>

      <Material.Paper sx={{ padding: 2, marginBottom: 4 }}>
        <Material.Typography
          variant="h6"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Other Skills (Non-WES)
        </Material.Typography>
        <Material.Grid container spacing={2}>
          {skillsList.map((skill) => (
            <Material.Grid item key={skill.id} xs={12} sm={6}>
              <Material.FormControlLabel
                control={
                  <Material.Checkbox
                    checked={otherSkills.includes(skill.id.toString())}
                    onChange={handleSkillChange}
                    value={skill.id}
                  />
                }
                label={skill.name}
              />
            </Material.Grid>
          ))}
        </Material.Grid>
      </Material.Paper>

      <Material.Button
        color="primary"
        onClick={handleSubmitEmploymentStatusAndExperience}
        sx={{
          marginLeft: "10px",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#7ab3ef",
          },
        }}
        disabled={initialSave}
      >
        Save
      </Material.Button>
      <LoadingSpinner loading={loading} />
    </Material.Box>
  );
};

export default EmploymentStatusContent;
