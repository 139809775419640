import * as Material from "@mui/material";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import DataPassing from "../../helpers/DataPassing";

// Pages
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Dashboard from "../pages/Dashboard";
import PageNotFound from "../pages/common/PageNotFound";
import Users from "../pages/Users/Users";
import Profile from '../pages/Profile/Profile';
import About from "../pages/About/About";
import MyAccount from "../pages/MyAccount/MyAccount";
import SkillsManagement from "../pages/Management/SkillsManagement";
import MainForm from "../pages/Forms/MainForm";
import DoleProgram from "../pages/Management/DoleProgram";
import EmploymentStatus from "../pages/Management/EmploymentStatusManagement";
import DoleProgramManagement from "../pages/Management/DoleProgramManagement";
import ProfileForm from "../pages/Forms/Profile.Form";

import Capitol from "../assets/bsrsbg1.png";

const defaultData = {
  theme:
    !DataPassing.Get("theme") || DataPassing.Get("theme").length === 0
      ? "light"
      : DataPassing.Get("theme"),
};

function MainContainer() {
  const [stateData, setStateData] = useState(defaultData);
  const [openDrawer, setOpenDrawer] = useState(false); // State to track drawer open/close
  const { theme } = stateData;

  const darkTheme = Material.createTheme({
    palette: {
      mode: theme,
    },
  });

  const dispatch = useDispatch();
  const SetTheme = (value) => {
    setStateData((prevState) => ({
      ...prevState,
      theme: value,
    }));
    dispatch({ type: "SET_THEME", payload: value });
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <BrowserRouter>
      <Material.ThemeProvider theme={darkTheme}>
        <ToastContainer floatingTime={5000} />
        <Material.Box
          sx={{
            height: "100vh", // Full viewport height
            display: "flex",
            backgroundImage: `url(${Capitol})`,
            backgroundRepeat: "no-repeat",
            viewpoint: "center",
            backgroundSize: "cover",
            padding: "-15",
          }}
        >
          <Navbar
            mode={theme}
            SetTheme={SetTheme}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Material.Box
            component="main"
            sx={{
              flexGrow: 1,
              overflow: "auto",
              transition: "margin-left 0.2s ease", // Smooth transition for margin change
              marginLeft: openDrawer ? 200 : -10, // Adjust margin when drawer is open
              marginTop: 8,
            }}
          >
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/profiles" element={<Profile />} />
              <Route path="/about" element={<About />} />
              <Route path="/myaccount" element={<MyAccount />} />
              <Route path="/skills" element={<SkillsManagement />} />
              <Route path="/forms" element={<MainForm />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/doleprogram" element={<DoleProgram />} />
              <Route
                path="/doleprogrammanagement"
                element={<DoleProgramManagement />}
              />
              <Route path="/employmentstatus" element={<EmploymentStatus />} />
              <Route path="/printform" element={<ProfileForm />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Material.Box>
        </Material.Box>
      </Material.ThemeProvider>
    </BrowserRouter>
  );
}

export default MainContainer;