// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  table {
    width: 100%; /* Full width to avoid overflow */
    font-size: 12px; /* Reduce font size slightly */
  }

  tr {
    page-break-inside: avoid; /* Prevent row splitting */
  }

  thead {
    display: table-header-group; /* Ensures header repeats on new pages */
  }

  tfoot {
    display: table-footer-group; /* Ensures footer remains at the bottom */
  }

  .print-container {
    page-break-before: always; /* Ensures new sections start on new pages */
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Forms/Profile.Form.Style.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW,EAAE,iCAAiC;IAC9C,eAAe,EAAE,8BAA8B;EACjD;;EAEA;IACE,wBAAwB,EAAE,0BAA0B;EACtD;;EAEA;IACE,2BAA2B,EAAE,wCAAwC;EACvE;;EAEA;IACE,2BAA2B,EAAE,yCAAyC;EACxE;;EAEA;IACE,yBAAyB,EAAE,4CAA4C;EACzE;AACF","sourcesContent":["@media print {\r\n  table {\r\n    width: 100%; /* Full width to avoid overflow */\r\n    font-size: 12px; /* Reduce font size slightly */\r\n  }\r\n\r\n  tr {\r\n    page-break-inside: avoid; /* Prevent row splitting */\r\n  }\r\n\r\n  thead {\r\n    display: table-header-group; /* Ensures header repeats on new pages */\r\n  }\r\n\r\n  tfoot {\r\n    display: table-footer-group; /* Ensures footer remains at the bottom */\r\n  }\r\n\r\n  .print-container {\r\n    page-break-before: always; /* Ensures new sections start on new pages */\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
