import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as Material from "@mui/material";
import { enUS } from "date-fns/locale";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Service from "../../services/ProfileService";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";

import PGBHLogo from "../assets/PGBh.png";
import BagongPilipinas from "../assets/BAGONG-PILIPINAS-LOGO.png";
import BEPO from "../assets/bepo.png";
import PESO from "../assets/peso.png";
import MOTTO from "../assets/BEPO_motto.png";

import axios from "axios";

const COLORS = [
  "#0088FE",
  "#f06292",
  "#FFBB28",
  "#FF8042",
  "#c2185b",
  "#8884d8",
  "#8e24aa",
  "#d4e157",
  "#ff7043",
  "#9e9d24",
  "#3949ab",
  "#26a69a",
  "#ffca28",
  "#5c6bc0",
  "#00C49F",
  "#7b1fa2",
  "#afb42b",
  "#29b6f6",
  "#ef5350",
  "#ab47bc",
  "#66bb6a",
  "#ffa726",
  "#42a5f5",
  "#7e57c2",
  "#d32f2f",
  "#388e3c",
  "#1976d2",
  "#c2185b",
  "#689f38",
  "#0288d1",
  "#d81b60",
  "#8e24aa",
  "#fbc02d",
  "#512da8",
  "#e64a19",
  "#f57c00",
  "#5d4037",
  "#00796b",
  "#f4511e",
  "#6d4c41",
  "#0288d1",
  "#00796b",
  "#f57c00",
  "#7e57c2",
  "#d81b60",
  "#0288d1",
  "#c2185b",
  "#689f38",
];

function Dashboard() {
  const user_data = useSelector((state) => state.user);
  const componentRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [genderCounts, setGenderCounts] = useState({ male: 0, female: 0 });
  const [municipalDataDistrict1, setMunicipalDataDistrict1] = useState([]);
  const [municipalDataDistrict2, setMunicipalDataDistrict2] = useState([]);
  const [municipalDataDistrict3, setMunicipalDataDistrict3] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [activeProgramCounts, setActiveProgramCounts] = useState([]);
  const [totalProgramCounts, setTotalProgramCounts] = useState([]);
  const [empAccomplishments, setEmpAccomplishments] = useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    {
      user_data.role_id === 1
        ? fetchEmployeeAccomplishments()
        : fetchMyAccomplishments();
    }
    let defaultYear = new Date().getFullYear();
  }, [dateRange]);

  const years = Array.from(
    new Array(10),
    (val, index) => new Date().getFullYear() - index
  ); // Last 10 years

  const formatDate = (date) => {
    return date.toLocaleDateString("en-CA"); // Formats to YYYY-MM-DD
  };

  const fetchEmployeeAccomplishments = async () => {
    let values = {
      date_from: formatDate(dateRange[0].startDate),
      date_to: formatDate(dateRange[0].endDate),
    };

    try {
      const data = await Service.getAccomplishments(values);
      setEmpAccomplishments(data);
    } catch (error) {
      console.error("Error fetching employee accomplishments:", error);
    }
  };

  const fetchMyAccomplishments = async () => {
    let values = {
      date_from: formatDate(dateRange[0].startDate),
      date_to: formatDate(dateRange[0].endDate),
      user_id: user_data.id,
    };

    try {
      const data = await Service.getMyAccomplishments(values);
      setEmpAccomplishments(data);
    } catch (error) {
      console.error("Error fetching employee accomplishments:", error);
    }
  };

  const fetchGenderCounts = async (year) => {
    try {
      const data = await Service.getCountGender(year);
      setGenderCounts(data);
    } catch (error) {
      console.error("Error fetching employee accomplishments:", error);
    }
  };

  const fetchMunicipalData = async (district, year) => {
    try {
      let data;

      if (district === 1) {
        data = await Service.getDistrict1Data(year);
        setMunicipalDataDistrict1(
          data.map((item, index) => ({
            id: index,
            value: item.COUNT,
            label: item.municipal_name,
          }))
        );
      } else if (district === 2) {
        data = await Service.getDistrict2Data(year);
        setMunicipalDataDistrict2(
          data.map((item, index) => ({
            id: index,
            value: item.COUNT,
            label: item.municipal_name,
          }))
        );
      } else {
        data = await Service.getDistrict3Data(year);
        setMunicipalDataDistrict3(
          data.map((item, index) => ({
            id: index,
            value: item.COUNT,
            label: item.municipal_name,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching municipal data:", error);
    }
  };

  const fetchActiveProgramCounts = async () => {
    try {
      const data = await Service.getActiveProgramCount();
      setActiveProgramCounts(data);
    } catch (error) {
      console.error("Error fetching employee accomplishments:", error);
    }
  };

  const fetchTotalProgramCounts = async () => {
    try {
      const data = await Service.getTotalProgramCount();
      setTotalProgramCounts(data);
    } catch (error) {
      console.error("Error fetching total program count:", error);
    }
  };

  useEffect(() => {
    fetchGenderCounts(selectedYear);
    fetchMunicipalData(1, selectedYear);
    fetchMunicipalData(2, selectedYear);
    fetchMunicipalData(3, selectedYear);

    fetchActiveProgramCounts();
    fetchTotalProgramCounts();
  }, [selectedYear]);

  const genderData = [
    { name: "Male", value: genderCounts.male },
    { name: "Female", value: genderCounts.female },
  ];

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "Accomplishment Report",
  });

  const handleDownloadExcel = () => {
    // Define the table data
    const data = empAccomplishments.map((item) => ({
      Name: item.full_name || "N/A",
      "No. of Entries Encoded": item.accomplishment || 0,
    }));

    // Create a worksheet
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Accomplishment Report");

    // Write and save Excel file
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, `Accomplishment_Report${fileExtension}`);
  };

  const toggleDatePicker = () => {
    setShowDatePicker((prev) => !prev);
  };

  return (
    <Material.Box
      flex={4}
      height="90vh"
      sx={{ maxWidth: "90%", margin: "0 auto", overflow: "hidden" }}
    >
      <Material.Box
        paddingLeft={{ xs: 2, sm: 3, md: 3 }}
        paddingTop={{ xs: 1, sm: 2, md: 2 }}
      >
        {/* <Material.Typography
          fontWeight="bold"
          variant="h4"
          fontFamily="Sen, sans-serif"
          marginTop={1}
        >
          Dashboard
        </Material.Typography> */}
        <Material.Box
          sx={{ display: "flex", justifyContent: "left", marginTop: 2 }}
        >
          <Material.Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Material.Tab label="District 1" />
            <Material.Tab label="District 2" />
            <Material.Tab label="District 3" />
            <Material.Tab label="Gender" />
            <Material.Tab label="Accomplishment Report" />
          </Material.Tabs>
        </Material.Box>
        <Material.Grid
          container
          spacing={2}
          sx={{ padding: 2, justifyContent: "center", maxWidth: "100vw" }} // Centering the content
        >
          {tabIndex !== 4 ? (
            <Material.Grid item xs={12} md={8} height="calc(100vh - 200px)">
              {" "}
              {/* 2/3 of the dashboard */}
              {tabIndex === 0 && (
                <Material.Card
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(3px)",
                    borderRadius: "15px",
                    height: "100%",
                    position: "relative", // Add relative positioning
                  }}
                >
                  <Material.CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      height: "100%", // Make content fill the card height
                    }}
                  >
                    <Material.FormControl
                      fullWidth
                      variant="standard"
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        zIndex: 1,
                        width: 150, // Adjust the width here
                      }}
                    >
                      <Material.InputLabel id="year-select-label">
                        Year
                      </Material.InputLabel>
                      <Material.Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedYear}
                        onChange={handleYearChange}
                        label="Year"
                      >
                        {years.map((year) => (
                          <Material.MenuItem key={year} value={year}>
                            {year}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.Typography
                      fontWeight="bold"
                      variant="h4"
                      fontFamily="Sen, sans-serif"
                      marginTop={15}
                    >
                      District 1 Municipal Count
                    </Material.Typography>
                    <ResponsiveContainer width="100%" height="100%">
                      {municipalDataDistrict1 &&
                      municipalDataDistrict1.length > 0 ? (
                        <PieChart width={400} height={400}>
                          <Pie
                            data={municipalDataDistrict1.map((entry) => ({
                              name: entry.label, // Ensure correct label mapping
                              value: entry.value > 0 ? entry.value : 0.01, // Ensure at least a minimal value
                            }))}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            fill="#8884d8"
                            label
                          >
                            {municipalDataDistrict1.map((entry, index) => {
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      ) : (
                        <p>No data available</p>
                      )}
                    </ResponsiveContainer>
                  </Material.CardContent>
                </Material.Card>
              )}
              {tabIndex === 1 && (
                <Material.Card
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(3px)",
                    borderRadius: "15px",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Material.CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      height: "100%",
                    }}
                  >
                    <Material.FormControl
                      fullWidth
                      variant="standard"
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        zIndex: 1,
                        width: 150,
                      }}
                    >
                      <Material.InputLabel id="year-select-label">
                        Year
                      </Material.InputLabel>
                      <Material.Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedYear}
                        onChange={handleYearChange}
                        label="Year"
                      >
                        {years.map((year) => (
                          <Material.MenuItem key={year} value={year}>
                            {year}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.Typography
                      fontWeight="bold"
                      variant="h4"
                      fontFamily="Sen, sans-serif"
                      marginTop={15}
                    >
                      District 2 Municipal Count
                    </Material.Typography>
                    <ResponsiveContainer width="100%" height="100%">
                      {municipalDataDistrict2 &&
                      municipalDataDistrict2.length > 0 ? (
                        <PieChart width={400} height={400}>
                          <Pie
                            data={municipalDataDistrict2.map((entry) => ({
                              name: entry.label, // Ensure correct label mapping
                              value: entry.value > 0 ? entry.value : 0.01, // Ensure at least a minimal value
                            }))}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            fill="#8884d8"
                            label
                          >
                            {municipalDataDistrict2.map((entry, index) => {
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      ) : (
                        <p>No data available</p>
                      )}
                    </ResponsiveContainer>
                  </Material.CardContent>
                </Material.Card>
              )}
              {tabIndex === 2 && (
                <Material.Card
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(3px)",
                    borderRadius: "15px",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Material.CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      height: "100%",
                    }}
                  >
                    <Material.FormControl
                      fullWidth
                      variant="standard"
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        zIndex: 1,
                        width: 150,
                      }}
                    >
                      <Material.InputLabel id="year-select-label">
                        Year
                      </Material.InputLabel>
                      <Material.Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedYear}
                        onChange={handleYearChange}
                        label="Year"
                      >
                        {years.map((year) => (
                          <Material.MenuItem key={year} value={year}>
                            {year}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.Typography
                      fontWeight="bold"
                      variant="h4"
                      fontFamily="Sen, sans-serif"
                      marginTop={15}
                    >
                      District 3 Municipal Count
                    </Material.Typography>
                    <ResponsiveContainer width="100%" height="100%">
                      {municipalDataDistrict3 &&
                      municipalDataDistrict3.length > 0 ? (
                        <PieChart width={400} height={400}>
                          <Pie
                            data={municipalDataDistrict3.map((entry) => ({
                              name: entry.label, // Ensure correct label mapping
                              value: entry.value > 0 ? entry.value : 0.01, // Ensure at least a minimal value
                            }))}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={150}
                            fill="#8884d8"
                            label
                          >
                            {municipalDataDistrict3.map((entry, index) => {
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      ) : (
                        <p>No data available</p>
                      )}
                    </ResponsiveContainer>
                  </Material.CardContent>
                </Material.Card>
              )}
              {tabIndex === 3 && (
                <Material.Card
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(3px)",
                    borderRadius: "15px",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Material.CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      height: "100%",
                    }}
                  >
                    <Material.FormControl
                      fullWidth
                      variant="standard"
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        zIndex: 1,
                        width: 150,
                      }}
                    >
                      <Material.InputLabel id="year-select-label">
                        Year
                      </Material.InputLabel>
                      <Material.Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedYear}
                        onChange={handleYearChange}
                        label="Year"
                      >
                        {years.map((year) => (
                          <Material.MenuItem key={year} value={year}>
                            {year}
                          </Material.MenuItem>
                        ))}
                      </Material.Select>
                    </Material.FormControl>
                    <Material.Typography
                      fontWeight="bold"
                      variant="h4"
                      fontFamily="Sen, sans-serif"
                      marginTop={15}
                    >
                      Gender Distribution
                    </Material.Typography>
                    <ResponsiveContainer width="100%" height="100%">
                      {genderData && genderData.length > 0 ? (
                        <PieChart width={400} height={400}>
                          <Pie
                            data={genderData.map((entry) => ({
                              name: entry.name, // Ensure correct mapping
                              value: entry.value > 0 ? entry.value : 0.01, // Ensure at least a minimal value
                            }))}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={250}
                            fill="#8884d8"
                            label
                          >
                            {genderData.map((entry, index) => {
                              return (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      ) : (
                        <p>No data available</p>
                      )}
                    </ResponsiveContainer>
                  </Material.CardContent>
                </Material.Card>
              )}
            </Material.Grid>
          ) : (
            <Material.Grid
              item
              xs={12}
              md={12}
              height="calc(100vh - 200px)"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {tabIndex === 4 && (
                <Material.Card
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(3px)",
                    borderRadius: "15px",
                    height: "100%",
                    // width: "100%",
                    // maxWidth: "100vw",
                    position: "relative",
                    padding: 3,
                    overflow: "auto",
                  }}
                >
                  {/* Toggle Date Range Picker */}
                  {showDatePicker && (
                    <Material.Box sx={{ mt: 6 }}>
                      <DateRangePicker
                        onChange={(item) => setDateRange([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={dateRange}
                        direction="horizontal"
                        locale={enUS}
                      />
                    </Material.Box>
                  )}
                  <Material.CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      height: "100%",
                    }}
                  >
                    {/* Title Section */}
                    <Material.Typography variant="h6" color="gray">
                      Dashboard
                    </Material.Typography>
                    <Material.Typography fontWeight="bold" variant="h4">
                      Accomplishment Report
                    </Material.Typography>

                    {/* Buttons */}
                    <Material.Box
                      sx={{
                        position: "absolute",
                        top: 16,
                        right: 16,
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      {/* IconButton to toggle DatePicker */}
                      <Material.Tooltip title="Select Date">
                        <Material.IconButton onClick={toggleDatePicker}>
                          <DateRangeRoundedIcon />
                        </Material.IconButton>
                      </Material.Tooltip>

                      {/* Download Button */}
                      <Material.Button
                        variant="outlined"
                        startIcon={<FileDownloadRoundedIcon />}
                        onClick={() => handleDownloadExcel()}
                      >
                        Download
                      </Material.Button>

                      {/* Print Button */}
                      <Material.Button
                        variant="outlined"
                        startIcon={<LocalPrintshopRoundedIcon />}
                        onClick={() => handlePrint()}
                      >
                        Print
                      </Material.Button>
                    </Material.Box>

                    {/* Search Fields */}
                    <Material.Box
                      ref={componentRef}
                      sx={{
                        width: "80vw",
                        height: "80vh",
                        marginTop: 8,
                        textAlign: "center",
                        "@media print": {
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100vw", // Full page width
                          margin: "auto", // Centers horizontally
                          marginTop: 8,
                        },
                      }}
                    >
                      {/* <Material.Paper
                        elevation={0}
                        sx={{
                          padding: 2,
                          height: "100vh",
                          display: "flex",
                          flexDirection: "column",
                          width: "100vw",
                        }}
                      > */}
                      {/* Header with Logos */}
                      <Material.Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          px: 5,
                        }}
                      >
                        {/* Left Logos */}
                        <Material.Box sx={{ display: "flex", gap: 2, ml: 22 }}>
                          <Material.Box
                            component="img"
                            src={BagongPilipinas}
                            sx={{ height: 100 }}
                          />
                          <Material.Box
                            component="img"
                            src={PGBHLogo}
                            sx={{ height: 90 }}
                          />
                        </Material.Box>

                        {/* Centered Title */}
                        <Material.Box>
                          <Material.Typography variant="body1">
                            Republic of the Philippines
                          </Material.Typography>
                          <Material.Typography
                            variant="h6"
                            sx={{ fontWeight: "bold" }}
                          >
                            PROVINCE OF BOHOL
                          </Material.Typography>
                          <Material.Typography variant="body1">
                            City of Tagbilaran
                          </Material.Typography>
                          <Material.Typography variant="body1">
                            Bohol Employment and Placement Office
                          </Material.Typography>
                          <Material.Typography variant="body1">
                            Public Employment Service Office (BEPO-PESO)
                          </Material.Typography>
                        </Material.Box>

                        {/* Right Logos */}
                        <Material.Box sx={{ display: "flex", gap: 1 }}>
                          <Material.Box
                            component="img"
                            src={BEPO}
                            sx={{ height: 100 }}
                          />
                          <Material.Box
                            component="img"
                            src={PESO}
                            sx={{ height: 100 }}
                          />
                          <Material.Box
                            component="img"
                            src={MOTTO}
                            sx={{ height: 120 }}
                          />
                        </Material.Box>
                      </Material.Box>

                      {/* Report Title */}
                      <Material.Typography
                        variant="h6"
                        sx={{ marginTop: 2, fontWeight: "bold" }}
                      >
                        Accomplishment Report
                      </Material.Typography>
                      <Material.Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                      >
                        {`${dateRange[0].startDate.toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })} to ${dateRange[0].endDate.toLocaleDateString(
                          "en-US",
                          {
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}`}
                      </Material.Typography>

                      {/* Table */}
                      <Material.TableContainer
                        sx={{
                          marginTop: 4,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Material.Table
                          sx={{
                            width: "50%",
                            border: "1px solid black",
                            borderCollapse: "collapse",
                          }}
                        >
                          <Material.TableHead>
                            <Material.TableRow>
                              <Material.TableCell
                                sx={{
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  border: "1px solid black",
                                }}
                              >
                                Name
                              </Material.TableCell>
                              <Material.TableCell
                                sx={{
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  border: "1px solid black",
                                }}
                              >
                                No. of Entries Encoded
                              </Material.TableCell>
                            </Material.TableRow>
                          </Material.TableHead>
                          <Material.TableBody>
                            {empAccomplishments.length > 0 ? (
                              empAccomplishments.map((accomplishment) => (
                                <Material.TableRow key={accomplishment.id}>
                                  <Material.TableCell
                                    sx={{
                                      textAlign: "center",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {accomplishment.full_name || "N/A"}
                                  </Material.TableCell>
                                  <Material.TableCell
                                    sx={{
                                      textAlign: "center",
                                      border: "1px solid black",
                                    }}
                                  >
                                    {accomplishment.accomplishment || 0}
                                  </Material.TableCell>
                                </Material.TableRow>
                              ))
                            ) : (
                              // Center the text inside a TableRow and TableCell
                              <Material.TableRow>
                                <Material.TableCell
                                  colSpan={2}
                                  sx={{ textAlign: "center", height: "150px" }}
                                >
                                  <Material.Typography
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "100%",
                                      textAlign: "center",
                                    }}
                                  >
                                    No accomplishment/s for the selected date
                                  </Material.Typography>
                                </Material.TableCell>
                              </Material.TableRow>
                            )}
                          </Material.TableBody>
                        </Material.Table>
                      </Material.TableContainer>
                      <Material.Box
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          padding: "40px 0",
                        }}
                      >
                        <Material.Typography
                          sx={{
                            color: "red",
                            fontStyle: "italic",
                            fontSize: "8px",
                          }}
                        >
                          <span
                            style={{ fontWeight: "bold", fontStyle: "normal" }}
                          >
                            NOTE:
                          </span>{" "}
                          Added and Edited data on the same date will count as 1
                          accomplishment.
                        </Material.Typography>
                      </Material.Box>
                      {/* </Material.Paper> */}
                    </Material.Box>
                  </Material.CardContent>
                </Material.Card>
              )}
            </Material.Grid>
          )}

          {tabIndex !== 4 && (
            <Material.Grid item xs={12} md={4} height="calc(100vh - 200px)">
              <Material.Card
                sx={{
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(3px)",
                  borderRadius: "15px",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Material.CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    height: "100%",
                  }}
                >
                  <Material.Typography
                    fontWeight="bold"
                    variant="h6"
                    fontFamily="Sen, sans-serif"
                    marginTop={2}
                  >
                    Active Programs Today
                  </Material.Typography>
                  <Material.List>
                    {activeProgramCounts?.map((program, index) => (
                      <Material.ListItem key={index}>
                        {program.program_name}: {program.active_count}
                      </Material.ListItem>
                    ))}
                  </Material.List>
                  <Material.Typography
                    fontWeight="bold"
                    variant="h6"
                    fontFamily="Sen, sans-serif"
                    marginTop={2}
                  >
                    Total Programs
                  </Material.Typography>
                  <Material.List>
                    {totalProgramCounts.map((program, index) => (
                      <Material.ListItem key={index}>
                        {program.program_name}: {program.total_count}
                      </Material.ListItem>
                    ))}
                  </Material.List>
                </Material.CardContent>
              </Material.Card>
            </Material.Grid>
          )}
        </Material.Grid>
      </Material.Box>
    </Material.Box>
  );
}

export default Dashboard;
