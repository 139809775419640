import React, { useEffect, useState } from "react";
import * as Material from "@mui/material";
import { Tooltip, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DoleProgramService from "../../../services/DoleProgramService";
import DoleProgramAdd from "./DoleProgramAdd";
import LoadingSpinner from "../../common/LoadingSpinner";

function DoleProgram() {
  const [allDoleProgram, setAllDoleProgram] = useState([]);
  const [filteredDoleProgram, setFilteredDoleProgram] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [year] = useState("");
  const [program] = useState("");
  const [hasFetchedAvailments, setHasFetchedAvailments] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const ShowModal = () => {
    setModalVisible(true);
  };

  const HideModal = () => {
    setModalVisible(false);
  };

  const onEdit = (row) => {
    setSelectedRow(row);
    ShowModal();
  };

  useEffect(() => {
    if (!hasFetchedAvailments) {
      fetchDoleAvailmentsSummary();
      setHasFetchedAvailments(true);
    }
  }, [hasFetchedAvailments]);

  useEffect(() => {
    filterDoleProgram();
  }, [year, program, searchTerm, allDoleProgram]);

  const fetchDoleAvailmentsSummary = async () => {
    setLoading(true);
    try {
      const data = await DoleProgramService.getDoleAvailmentsSummary();
      const rowsWithId = data.map((row, index) => ({
        ...row,
        id: row.id !== undefined ? row.id : index,
      }));
      setAllDoleProgram(rowsWithId);
      setFilteredDoleProgram(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error("$$Error fetching ProgramAvailments details:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  const filterDoleProgram = () => {
    let filtered = allDoleProgram;
    if (year) {
      filtered = filtered.filter(
        (record) => new Date(record.date_from).getFullYear() === parseInt(year)
      );
    }
    if (program) {
      filtered = filtered.filter((record) => record.program === program);
    }
    if (searchTerm) {
      filtered = filtered.filter(
        (record) =>
          record.name.toLowerCase().includes(searchTerm.toLowerCase()) || // Filter by fullname
          record.program_name.toLowerCase().includes(searchTerm.toLowerCase()) // Filter by program name
      );
    }
    setFilteredDoleProgram(filtered);
  };

  const onAdd = () => {
    setSelectedRow({});
    ShowModal();
  };

  const handleSave = async () => {
    await fetchDoleAvailmentsSummary();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    filterDoleProgram();
  };

  const columns = [
    { field: "name", headerName: "Fullname", width: 300 },
    { field: "address", headerName: "Address", width: 300 },
    { field: "program_name", headerName: "Program", width: 150 },
    { field: "availment", headerName: "Availment", width: 150 },
    { field: "date_from", headerName: "Date From", width: 150 },
    { field: "date_to", headerName: "Date To", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        const onClickEdit = () => {
          onEdit(params.row);
        };
        return (
          <Tooltip title="Edit">
            <IconButton onClick={onClickEdit} aria-label="edit" size="small">
              <EditIcon fontSize="inherit" sx={{ color: "blue" }} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Material.Box flex={4} height="80vh" sx={{ maxWidth: "100%" }}>
        <Material.Typography
          sx={{
            paddingTop: 4,
            paddingLeft: 10,
            fontSize: 30,
            fontWeight: "bold",
            letterSpacing: 1.5,
            background: "linear-gradient(90deg, #2575fc, #6dd5ed)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
            borderRadius: 1,
          }}
        >
          DOLE Program Availments
        </Material.Typography>
        <Material.Box paddingLeft={{ xs: 4, sm: 10, md: 0 }}>
          <Material.Box flex={4} height="80vh" sx={{ maxWidth: "95%" }}>
            <Material.Box
              paddingLeft={{ xs: 4, sm: 10, md: 10 }}
              paddingTop={{ xs: 1, sm: 2, md: 3 }}
            >
              <Material.Paper
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "15px",
                  border: "1px solid rgba(255, 255, 255, 0.18)",
                }}
              >
                {/* Add New and Search Field on Same Line */}
                <Material.Box
                  sx={{
                    padding: 1,
                    display: "flex",
                    justifyContent: "space-between", // Ensure space between Add New and Search
                    alignItems: "center", // Align vertically in the center
                  }}
                >
                  {/* Add New Button */}
                  <Material.Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={onAdd}
                  >
                    Add New
                  </Material.Button>

                  {/* Search Field and Button */}
                  <Material.Box
                    sx={{
                      display: "flex",
                      gap: 2, // Space between the search field and button
                      alignItems: "flex-end", // Align bottom of search field with the button
                    }}
                  >
                    <Material.TextField
                      label="Search by Fullname or Program"
                      variant="standard"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      sx={{ width: "230px" }}
                    />
                    <Material.Button variant="contained" onClick={handleSearch}>
                      Search
                    </Material.Button>
                  </Material.Box>
                </Material.Box>

                {/* Modal and DataGrid Section */}
                {modalVisible && (
                  <DoleProgramAdd
                    SetSelectedRow={setSelectedRow}
                    SelectedRow={selectedRow}
                    ModalVisible={modalVisible}
                    HideModal={HideModal}
                    onSave={handleSave}
                    fetchDoleAvailmentsSummary={fetchDoleAvailmentsSummary}
                  />
                )}
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={filteredDoleProgram}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5, 10, 15]}
                    components={{ Toolbar: GridToolbar }}
                  />
                </div>
              </Material.Paper>
            </Material.Box>
          </Material.Box>
        </Material.Box>
      </Material.Box>
      {loading && <LoadingSpinner loading={loading} />}
    </>
  );
}

export default DoleProgram;
