import API from "../helpers/AxiosHelper";

const ProfileService = {
  getProfileDetails: async (value) => {
    const req = {
      municipality_id: value.municipality_id,
      barangay_id: value.barangay_id,
      keyword: value.keyword,
      page_no: value.page_no,
      limit: value.limit,
      sort_column: value.sort_column,
      sort_type: value.sort_type,
      date_from: value.date_from,
      date_to: value.date_to,
    };
    try {
      const response = await API.post("/profile/getDetails", req);
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching profile details:", error);
      throw error;
    }
  },

  getProfile: async (profileId) => {
    try {
      const response = await API.post("/profile/getProfile", { profileId });
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching profile details:", error);
      throw error;
    }
  },

  saveProfileInfo: async (profileInfo) => {
    try {
      if (profileInfo.id) {
        const response = await API.put("/profile/update", profileInfo);
        return response.data;
      } else {
        const response = await API.post("/profile/save", profileInfo);
        return response.data;
      }
    } catch (error) {
      console.error("Error saving/updating profile information:", error);
      throw error;
    }
  },

  deleteProfile: async (_profiles_id) => {
    try {
      const response = await API.delete("/profile/delete", {
        data: { _profiles_id },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting profile:", error);
      throw error;
    }
  },

  searchProfiles: async (searchCriteria) => {
    try {
      const response = await API.post("/profile/search", searchCriteria);
      return response;
    } catch (error) {
      console.error("Error searching profiles:", error);
      throw error;
    }
  },

  filterProfilesByMunicipalName: async (municipalName) => {
    try {
      const response = await API.post("/profile/filterByMunicipalName", {
        municipalName,
      });
      return response.data;
    } catch (error) {
      console.error("Error filtering profiles by municipal name:", error);
      throw error;
    }
  },

  filterProfilesByBarangayName: async (barangayName) => {
    try {
      const response = await API.post("/profile/filterByBarangayName", {
        barangayName,
      });
      return response.data;
    } catch (error) {
      console.error("Error filtering profiles by barangay name:", error);
      throw error;
    }
  },

  getBarangaysByMunicipalName: async (municipalName) => {
    try {
      const response = await API.post("/profile/getBarangaysByMunicipalName", {
        municipalName,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching barangays:", error);
      throw error;
    }
  },

  getMunicipalities: async () => {
    try {
      const response = await API.get("/profile/getMunicipalities");
      return response[0];
    } catch (error) {
      console.error("Error fetching municipal name:", error);
      throw error;
    }
  },

  getProfilePerUser: async (profile_id) => {
    try {
      const response = await API.get("/profile/getUserDetails", {
        params: { profile_id },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  },

  advancedSearch: async (municipalName, barangayName) => {
    try {
      const response = await API.post("/profile/advancedSearch", {
        municipalName,
        barangayName,
      });
      return response.data;
    } catch (error) {
      console.error("Error performing advanced search:", error);
      throw error;
    }
  },

  getAccomplishments: async ({ date_from, date_to }) => {
    try {
      const response = await API.post("/bsrs/getEmpAccomplishments", {
        date_from,
        date_to,
      });
      return response.data;
    } catch (error) {
      console.error("API Error in getAccomplishments:", error.message || error);
      throw new Error(
        "Failed to fetch employee accomplishments. Please try again."
      );
    }
  },

  getMyAccomplishments: async ({ date_from, date_to, user_id }) => {
    try {
      const response = await API.post("/bsrs/getMyAccomplishment", {
        date_from,
        date_to,
        user_id,
      });
      return response.data;
    } catch (error) {
      console.error("API Error in getAccomplishments:", error.message || error);
      throw new Error(
        "Failed to fetch employee accomplishments. Please try again."
      );
    }
  },

  getCountGender: async (year) => {
    try {
      const response = await API.get("/gender/genders", {
        params: { year },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching gender count:", error);
      throw error;
    }
  },

  getActiveProgramCount: async () => {
    try {
      const response = await API.get("/countprograms/active-counts-today");
      return response.data;
    } catch (error) {
      console.error("Error fetching active program count:", error);
      throw error;
    }
  },

  getDistrict1Data: async (year) => {
    try {
      const response = await API.get("/district/municipality1", {
        params: { year },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching district 1 data count:", error);
      throw error;
    }
  },

  getDistrict2Data: async (year) => {
    try {
      const response = await API.get("/district/municipality2", {
        params: { year },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching district 2 data count:", error);
      throw error;
    }
  },

  getDistrict3Data: async (year) => {
    try {
      const response = await API.get("/district/municipality3", {
        params: { year },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching district 3 data count:", error);
      throw error;
    }
  },

  getTotalProgramCount: async (year) => {
    try {
      const response = await API.get("/countprograms/total-counts");
      return response.data;
    } catch (error) {
      console.error("Error fetching district 3 data count:", error);
      throw error;
    }
  },
};
export const DeleteProfile = ProfileService.deleteProfile;

export default ProfileService;
