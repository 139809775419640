import React, { useEffect, useState, useCallback  } from "react";
import { Delete } from "../../../services/SkillsService";
import * as Material from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SkillsService from "../../../services/SkillsService";
import SkillsManagementAdd from "./SkillsManagementAdd";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LoadingSpinner from "../../common/LoadingSpinner";

import { displayAlert } from "../../common/Alert";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

function SkillsManagement() {
  const [allSkills, setAllSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
 
  const fetchSkills = useCallback(async () => {
    setLoading(true);
    try {
      const data = await SkillsService.getSkills();
      const rowsWithId = data.map((row, index) => ({
        ...row,
        id: row.id !== undefined ? row.id : index,
      }));
      setAllSkills(rowsWithId);
    } catch (error) {
      console.error("Error fetching skills:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []); 
  
  useEffect(() => {
    fetchSkills();
  }, [fetchSkills]); 
  
  const onAdd = () => {
    setSelectedRow({});
    ShowModal();
    fetchSkills();
  };

  const onEdit = (row) => {
    setSelectedRow(row);
    ShowModal();
    fetchSkills();
  };

  const onDelete = () => {
    if (selectedRow && selectedRow.id) {
      Delete(selectedRow.id)
        .then((res) => {
          if (res.error) {
            displayAlert({
              message: res.message,
              severity: "error",
            });
          } else {
            displayAlert({
              message: "Skill deleted successfully!",
              severity: "success",
            });
            fetchSkills();  
          }
        })
        .catch((err) => {
          displayAlert({
            message: err,
            severity: "error",
          });
        })
        .finally(() => {
          setDialogVisible(false);
        });
    } else {
      displayAlert({
        message: "Nothing to delete!",
        severity: "warning",
      });
      setDialogVisible(false);
    }
  };

  const ShowModal = () => {
    setModalVisible(true);
  };

  const HideModal = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {params.value}
        </div>
      ),
    },
    { field: "added_by", headerName: "Added By", width: 120 },
    { field: "added_on", headerName: "Added On", width: 150 },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => {
        const onClickEdit = () => {
          onEdit(params.row);
        };
        const onClickDelete = () => {
          setSelectedRow(params.row);
          setDialogVisible(true);
        };
        return (
          <>
            <Tooltip title="Delete">
              <IconButton
                onClick={onClickDelete}
                aria-label="delete"
                size="small"
              >
                <DeleteIcon fontSize="inherit" sx={{ color: "red" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton onClick={onClickEdit} aria-label="edit" size="small">
                <EditIcon fontSize="inherit" sx={{ color: "blue" }} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Material.Box
        flex={4}
        height="70vh"
        sx={{ maxWidth: "100%", paddingLeft: -10 }}
      >
        <Material.Typography
          sx={{
            paddingTop: 4,
            paddingLeft: 10,
            fontSize: 30,
            fontWeight: "bold",
            letterSpacing: 1.5,
            background: "linear-gradient(90deg, #2575fc, #6dd5ed)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
            borderRadius: 1,
          }}
        >
          Skills
        </Material.Typography>
        <Material.Box paddingLeft={{ xs: 4, sm: 10, md: 0 }}>
          <Material.Box flex={4} height="70vh" sx={{ maxWidth: "95%" }}>
            <Material.Box
              paddingLeft={{ xs: 4, sm: 10, md: 10 }}
              paddingTop={{ xs: 1, sm: 2, md: 3 }}
            >
              <Material.Paper
                sx={{
                  background: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(3px)",
                  borderRadius: "15px",
                  border: "1px solid rgba(255, 255, 255, 0.18)",
                }}
              >
                <Material.Box sx={{ padding: 2 }}>
                  <Material.Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={onAdd}
                  >
                    Add New
                  </Material.Button>
                </Material.Box>

                {modalVisible && (
                  <SkillsManagementAdd
                    fetchSkills={fetchSkills} 
                    SelectedRow={selectedRow}
                    ModalVisible={modalVisible}
                    HideModal={HideModal}
                  />
                )}

                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={allSkills}
                    columns={columns}
                    pageSize={5}
                    components={{ Toolbar: GridToolbar }}
                    getRowId={(row) => row.id}
                  />
                </div>
                {/* )} */}
              </Material.Paper>
            </Material.Box>
          </Material.Box>
        </Material.Box>

        {/* Dialog for delete confirmation */}
        <Dialog
          open={dialogVisible}
          onClose={() => setDialogVisible(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Skill"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this skill?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogVisible(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={onDelete} color="error" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {loading && <LoadingSpinner loading={loading} />}
      </Material.Box>
    </>
  );
}

export default SkillsManagement;
