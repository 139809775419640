import React, { useEffect, useState } from "react";
import * as Material from "@mui/material";
import ProfileService from "../../../services/ProfileService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ViewProfile({ profileId, open, onClose }) {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [personalInformations, setPersonalInformations] = useState(null);
  const [personalTrainings, setPersonalTrainings] = useState([]);
  const [personalEligibility, setPersonalEligibility] = useState([]);
  const [doleProgramOfAvailments, setDoleProgramOfAvailments] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [jobPreference, setJobPreference] = useState([]);

  // useEffect(() => {
  //   const fetchProfileDetails = async () => {
  //     if (profileId) {
  //       setLoading(true);
  //       setError(null);
  //       try {
  //         const data = await ProfileService.getProfile(profileId);
  //         console.log("DATA: ", data);

  //         setProfileData(data);
  //         setLoading(false);
  //       } catch (error) {
  //         setError(error);
  //         setLoading(false);
  //         console.error("Error fetching profile details:", error);
  //       }
  //     }
  //   };

  //   fetchProfileDetails();
  // }, [profileId]);

  useEffect(() => {
    const fetchProfileDetails = async () => {
      if (profileId) {
        setLoading(true);
        setError(null);
        try {
          let profile_id = profileId;
          const data = await ProfileService.getProfilePerUser(profile_id);
          console.log("DATA: ", data);
          setPersonalInformations(data.result0[0]);
          setPersonalTrainings(data.result1);
          setPersonalEligibility(data.result2);
          setDoleProgramOfAvailments(data.result3);
          setWorkExperience(data.result4);
          setJobPreference(data.result5[0]);

          // setProfileData(data);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
          console.error("Error fetching profile details:", error);
        }
      }
    };

    fetchProfileDetails();
  }, [profileId]);

  const handleClose = (event, reason) => {
    // if (reason && reason === "backdropClick") {
    //   // Do nothing or add any specific behavior if needed
    //   return;
    // }
    onClose();
  };

  const formattedDate = (dateString) => {
    if (!dateString) return null; // Handle cases where date is null or undefined
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Material.Box
      component="div"
      elevation={5}
      sx={{ width: "100%", overflow: "auto", height: "530px" }}
    >
      <Material.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="view-profile-dialog"
        fullWidth
        maxWidth="xl"
      >
        <Material.Paper
          sx={{ padding: 3, overflow: "auto" }}
          elevation={3}
          square={false}
        >
          <Material.DialogTitle
            h2
            id="view-profile-dialog"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Profile details
          </Material.DialogTitle>

          <Material.Accordion defaultExpanded>
            <Material.AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Personal Information:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              <Material.Grid container spacing={2}>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    First name:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.firstname}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Middle name:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.middlename}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Last name:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.lastname}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Suffix:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.suffix || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Date of Birth:{" "}
                    <span style={{ color: "blue" }}>
                      {formattedDate(personalInformations?.birthdate)}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Age:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.age}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Religion:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.religion}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Civil Status:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.civil_status}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Sex:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.gender}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Educational background:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.educ_status}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Email:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.email}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Landline Number:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.landline || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Cellphone Number:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.mobile}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Type of Government ID:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.type_of_govt_id}
                    </span>
                  </Material.Typography>
                </Material.Grid>{" "}
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    ID No:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.govt_id_no}
                    </span>
                  </Material.Typography>
                </Material.Grid>{" "}
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Height:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.height_ft}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Father's Name:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.fathers_name}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Occupation:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.occupation_father}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Contact No.:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.contact_no_father}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Mother's Name:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.mothers_name}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Occupation:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.occupation_mother}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Contact No.:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.contact_no_mother}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Parent/s Civil Status:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.parents_civil_status}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Parent/s Unemployment Type:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.parents_unemployment_type}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Parent/s Other Info.:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.parents_other_info}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Disability:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.disabilities || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Language/Dialect:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.dialect}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}></Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>Present Address:</Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Postal Code:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.postal_code}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    State/Province:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.province}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    City/municipality:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.municipality}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Barangay:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.barangay_name}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Street Address:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.street_address}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>Permanent Address:</Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Postal Code:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.permanent_postal_code}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    State/Province:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.permanent_province_name}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    City/municipality:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.permanent_municipality}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Barangay:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.permanent_barangay}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Street Address:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.permanent_street_address}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}></Material.Grid>
              </Material.Grid>
            </Material.AccordionDetails>
          </Material.Accordion>
          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Educational Background:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              <Material.Grid container spacing={2}>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Educational Type :{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.educ_type}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Educational Status :{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.educ_status}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Elementary:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.elem_school_name || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    Elementary Level:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.elem_level_reached || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Year Graduated/Last Attended:{" "}
                    <span style={{ color: "blue" }}>
                      {(personalInformations?.elem_yr_graduated > 0 &&
                        personalInformations?.elem_yr_graduated) ||
                        "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    High School:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.high_school_name || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    High School Level:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.high_school_level_reached || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Senior High Strand:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.senior_high_strand || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    Year Graduated/Last Attended:{" "}
                    <span style={{ color: "blue" }}>
                      {(personalInformations?.high_yr_graduated > 0 &&
                        personalInformations?.high_yr_graduated) ||
                        "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Vocational:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.voc_school_name || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    Vocational Course:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.voc_course || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Vocational Level:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.voc_level_reached || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    Year Graduated/Last Attended:{" "}
                    <span style={{ color: "blue" }}>
                      {(personalInformations?.voc_yr_graduated > 0 &&
                        personalInformations?.voc_yr_graduated) ||
                        "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    College:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.college_school_name || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    College Course:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.college_course || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    College Level:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.college_level_reached || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    Year Graduated/Last Attended:{" "}
                    <span style={{ color: "blue" }}>
                      {(personalInformations?.college_yr_graduated > 0 &&
                        personalInformations?.college_yr_graduated) ||
                        "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Masteral Graduate School:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.masteral_school_name || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    Masteral Course:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.masteral_course || "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
                <Material.Grid item xs={6}>
                  <Material.Typography>
                    Masteral Level:{" "}
                    <span style={{ color: "blue" }}>
                      {personalInformations?.masteral_level_reached || "N/A"}
                    </span>
                  </Material.Typography>
                  <Material.Typography>
                    Year Graduated/Last Attended:{" "}
                    <span style={{ color: "blue" }}>
                      {(personalInformations?.masteral_yr_graduated > 0 &&
                        personalInformations?.masteral_yr_graduated) ||
                        "N/A"}
                    </span>
                  </Material.Typography>
                </Material.Grid>
              </Material.Grid>
            </Material.AccordionDetails>
          </Material.Accordion>

          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Training/Vocational Courses:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              {/* Check if personalTrainings exists and map over the array */}
              {personalTrainings && personalTrainings.length > 0 ? (
                personalTrainings.map((training, index) => (
                  <>
                    {personalTrainings.length > 1 && (
                      <Material.Typography color="red" marginBottom={2}>
                        {index + 1}
                      </Material.Typography>
                    )}
                    <Material.Grid
                      container
                      spacing={1}
                      key={index}
                      sx={{ marginBottom: 4 }}
                    >
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Training/Vocational Course:{" "}
                          <span style={{ color: "blue" }}>
                            {training?.name}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Hours of Training:{" "}
                          <span style={{ color: "blue" }}>
                            {training?.hours_of_training}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Training Institution:{" "}
                          <span style={{ color: "blue" }}>
                            {training?.institution}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Skills Acquired:{" "}
                          <span style={{ color: "blue" }}>
                            {training?.skills_acquired}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Certificates Received:{" "}
                          <span style={{ color: "blue" }}>
                            {training?.certificate_received}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                    </Material.Grid>
                  </>
                ))
              ) : (
                <Material.Typography>
                  No trainings available
                </Material.Typography>
              )}
            </Material.AccordionDetails>
          </Material.Accordion>

          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Eligibilities:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              {/* Check if personalEligibility exists and map over the array */}
              {personalEligibility && personalEligibility.length > 0 ? (
                personalEligibility.map((eligibility, index) => (
                  <>
                    {personalEligibility.length > 1 && (
                      <Material.Typography color="red" marginBottom={2}>
                        {index + 1}
                      </Material.Typography>
                    )}
                    <Material.Grid
                      container
                      spacing={2}
                      key={index}
                      sx={{ marginBottom: 4 }}
                    >
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Eligibility:{" "}
                          <span style={{ color: "blue" }}>
                            {eligibility?.eligibility}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Date of Taken:{" "}
                          <span style={{ color: "blue" }}>
                            {formattedDate(eligibility?.exam_date)}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Professional License:{" "}
                          <span style={{ color: "blue" }}>
                            {eligibility?.license}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Valid Until:{" "}
                          <span style={{ color: "blue" }}>
                            {formattedDate(eligibility?.valid_until)}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                    </Material.Grid>
                  </>
                ))
              ) : (
                <Material.Typography>
                  No eligibility data available
                </Material.Typography>
              )}
            </Material.AccordionDetails>
          </Material.Accordion>
          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Work Experience:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              {/* Check if personalTrainings exists and map over the array */}
              {workExperience && workExperience.length > 0 ? (
                workExperience.map((workExperience, index) => (
                  <>
                    {workExperience.length > 1 && (
                      <Material.Typography color="red" marginBottom={2}>
                        {index + 1}
                      </Material.Typography>
                    )}
                    <Material.Grid
                      container
                      spacing={1}
                      key={index}
                      sx={{ marginBottom: 4 }}
                    >
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Company Name:{" "}
                          <span style={{ color: "blue" }}>
                            {workExperience?.company_name}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Address:{" "}
                          <span style={{ color: "blue" }}>
                            {workExperience?.address}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Position:{" "}
                          <span style={{ color: "blue" }}>
                            {workExperience?.position}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Status:{" "}
                          <span style={{ color: "blue" }}>
                            {workExperience?.status}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Number of Month/s:{" "}
                          <span style={{ color: "blue" }}>
                            {workExperience?.no_of_months}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                    </Material.Grid>
                  </>
                ))
              ) : (
                <Material.Typography>
                  No work experience available
                </Material.Typography>
              )}
            </Material.AccordionDetails>
          </Material.Accordion>

          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Employment Status:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              <React.Fragment>
                <Material.Grid container spacing={1} sx={{ marginBottom: 4 }}>
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Employment Status:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.employment_status}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Employment Type:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.employed_type}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  {personalInformations?.employment_status === "Employed" &&
                    personalInformations?.employed_type === "Self Employed" && (
                      <Material.Grid item xs={12}>
                        <Material.Typography>
                          Occupation/Job:{" "}
                          <span style={{ color: "blue" }}>
                            {personalInformations?.self_employed_status ||
                              personalInformations?.other_jobs}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                    )}
                  {personalInformations?.employment_status === "Employed" &&
                    personalInformations?.employed_type === "Wage Employed" && (
                      <Material.Grid item xs={12}>
                        <Material.Typography>
                          Occupation/Job:{" "}
                          <span style={{ color: "blue" }}>
                            {personalInformations?.wage_employed_status ||
                              personalInformations?.other_jobs}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                    )}
                  {personalInformations?.employment_status === "Unemployed" && (
                    <Material.Grid item xs={12}>
                      <Material.Typography>
                        Occupation/Job:{" "}
                        <span style={{ color: "blue" }}>
                          {personalInformations?.unemployed_status ||
                            personalInformations?.other_jobs}
                        </span>
                      </Material.Typography>
                    </Material.Grid>
                  )}
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Are you an OFW ?:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.is_ofw === 1 ? "Yes" : "No"}
                      </span>
                    </Material.Typography>
                    <Material.Typography>
                      Country ?:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.ofw_country}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Are you a former OFW ?:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.is_former_ofw === 1
                          ? "Yes"
                          : "No"}
                      </span>
                    </Material.Typography>
                    <Material.Typography>
                      Latest Country of Deployment ?:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.latest_country_of_deployment}
                      </span>
                    </Material.Typography>
                    <Material.Typography>
                      Month and Year of Return to Philippines ?:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.return_year}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Are you a 4p's beneficiary ?:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.is_4ps === 1 ? "Yes" : "No"}
                      </span>
                    </Material.Typography>
                    <Material.Typography>
                      Household ID No.:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.household_id}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                </Material.Grid>
              </React.Fragment>
            </Material.AccordionDetails>
          </Material.Accordion>

          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Job Preference:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              <>
                <Material.Grid container spacing={1} sx={{ marginBottom: 4 }}>
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Preferred Occupation Type:{" "}
                      <span style={{ color: "blue" }}>
                        {jobPreference?.pref_occupation_type}
                      </span>
                    </Material.Typography>
                    <Material.Typography>
                      Preferred Occupation:{" "}
                      <span style={{ color: "blue" }}>
                        {jobPreference?.pref_occupation}
                      </span>
                    </Material.Typography>
                    <Material.Typography>
                      Preferred Work Location:{" "}
                      <span style={{ color: "blue" }}>
                        {jobPreference?.pref_work_location}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Local:{" "}
                      <span style={{ color: "blue" }}>
                        {jobPreference?.pref_local_location}
                      </span>
                    </Material.Typography>{" "}
                    <Material.Typography>
                      Overseas Country:{" "}
                      <span style={{ color: "blue" }}>
                        {jobPreference?.pref_overseas_country}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                </Material.Grid>
              </>
            </Material.AccordionDetails>
          </Material.Accordion>

          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Other Skills:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              <>
                <Material.Grid container spacing={1} sx={{ marginBottom: 4 }}>
                  <Material.Grid item xs={6}>
                    <Material.Typography>
                      Skill:{" "}
                      <span style={{ color: "blue" }}>
                        {personalInformations?.skill_name || "N/A"}
                      </span>
                    </Material.Typography>
                  </Material.Grid>
                </Material.Grid>
              </>
            </Material.AccordionDetails>
          </Material.Accordion>

          <Material.Accordion>
            <Material.AccordionSummary
              sx={{ marginTop: 1 }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Material.Typography sx={{ fontWeight: "bold" }}>
                Dole Program of Availments:
              </Material.Typography>
            </Material.AccordionSummary>
            <Material.AccordionDetails sx={{ marginLeft: 4 }}>
              {/* Check if personalEligibility exists and map over the array */}
              {doleProgramOfAvailments && doleProgramOfAvailments.length > 0 ? (
                doleProgramOfAvailments.map((dole_program, index) => (
                  <>
                    {doleProgramOfAvailments.length > 1 && (
                      <Material.Typography color="red" marginBottom={2}>
                        {index + 1}
                      </Material.Typography>
                    )}
                    <Material.Grid
                      container
                      spacing={2}
                      key={index}
                      sx={{ marginBottom: 4 }}
                    >
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Program Type:{" "}
                          <span style={{ color: "blue" }}>
                            {dole_program?.program_name}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Availment:{" "}
                          <span style={{ color: "blue" }}>
                            {dole_program?.availment}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Valid Until:{" "}
                          <span style={{ color: "blue" }}>
                            {formattedDate(dole_program?.date_to)}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                      <Material.Grid item xs={6}>
                        <Material.Typography>
                          Address:{" "}
                          <span style={{ color: "blue" }}>
                            {dole_program?.address}
                          </span>
                        </Material.Typography>
                      </Material.Grid>
                    </Material.Grid>
                  </>
                ))
              ) : (
                <Material.Typography>
                  No dole program of availments
                </Material.Typography>
              )}
            </Material.AccordionDetails>
          </Material.Accordion>

          <Material.DialogActions>
            <Material.Button onClick={onClose} color="primary">
              Close
            </Material.Button>
          </Material.DialogActions>
        </Material.Paper>
      </Material.Dialog>
    </Material.Box>
  );
}

export default ViewProfile;
