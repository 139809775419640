import * as Material from "@mui/material";
import React, { useEffect, useState } from "react";
import { ForceLogout } from "../../helpers/AxiosHelper";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import PGBhLogo from "../assets/PGBhlogo.png";

// icons used
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InfoIcon from "@mui/icons-material/Info";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WorkIcon from "@mui/icons-material/Work";
import SummarizeIcon from "@mui/icons-material/Summarize";

import ChangePasswordModal from "../pages/MyAccount/ChangePasswordModal";

const StyledToolbar = Material.styled(Material.Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

const drawerWidth = 220;

function Navbar({ mode, SetTheme }, props) {
  const user_name = useSelector((state) => state.user.name);
  const user_role = useSelector((state) => state.user.role_id);
  const [open, setOpen] = useState(false);
  const [modalVisibleChangePassword, setmodalVisibleChangePassword] =
    useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [openManagement, setOpenManagement] = useState(false); // State for nested menu
  const [hovered, setHovered] = useState(false); // State to track drawer hover
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
    setOpenDrawer(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setOpenDrawer(false);
    setOpenManagement(false); // Close nested menu when drawer is not hovered
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
    setOpenDrawer(false);
  };

  const handleManagementClick = () => {
    setOpenManagement(!openManagement);
  };

  const drawer = (
    <div>
      <Material.Toolbar />
      <Material.Divider />
      <Material.Box
        fontWeight="bold"
        sx={{ textAlign: "center" }}
        variant="h5"
      ></Material.Box>
      <Material.List>
        {[
          {
            text: "Dashboard",
            icon: <DashboardIcon sx={{ color: "#1976D2" }} />,
            href: "/",
          },
          {
            text: "Profile",
            icon: <AssignmentIndIcon sx={{ color: "#1976D2" }} />,
            href: "profiles",
          },
          ...(user_role === 1
            ? [
                {
                  text: "Users",
                  icon: <PeopleAltIcon sx={{ color: "#1976D2" }} />,
                  href: "users",
                },
              ]
            : []),
          ...(user_role === 2 ? [
              {
                text: "DOLE Program Availments",
                icon: <SummarizeIcon sx={{ color: "#1976D2" }} />,
                href: "doleprogram",
              },
          ] : [])
        ].map((item, index) => (
          <Material.ListItem
            disablePadding
            key={item.text}
            sx={{ margin: 0, padding: 0 }}
          >
            <Material.ListItemButton
              component="a"
              href={item.href}
              onClick={() => handleButtonClick(index)}
              sx={{
                "&:hover": { backgroundColor: "#88c3fc", color: "#1976D2" },
                "&:active": { backgroundColor: "#88c3fc", color: "white" },
                backgroundColor:
                  activeButton === index ? "#1976D2" : "transparent",
                color: activeButton === index ? "white" : "inherit",
              }}
            >
              <Material.ListItemIcon>{item.icon}</Material.ListItemIcon>
              <Material.ListItemText
                sx={{
                  display: {
                    xs: mobileOpen ? "block" : "none",
                    sm: openDrawer ? "block" : "none",
                  },
                }}
              >
                {item.text}
              </Material.ListItemText>
            </Material.ListItemButton>
          </Material.ListItem>
        ))}
      </Material.List>
      <Material.Divider />
      {user_role === 1 ?
      (
          <Material.List>
            <Material.ListItem disablePadding sx={{ margin: 0, padding: 0 }}>
              <Material.ListItemButton
                onClick={handleManagementClick}
                sx={{
                  "&:hover": { backgroundColor: "#88c3fc", color: "#1976D2" },
                  "&:active": { backgroundColor: "#88c3fc", color: "white" },
                  backgroundColor: openManagement ? "#1976D2" : "transparent",
                  color: openManagement ? "white" : "inherit",
                }}
              >
                <Material.ListItemIcon>
                  <ManageAccountsIcon
                    sx={{ color: openManagement ? "white" : "#1976D2" }}
                  />
                </Material.ListItemIcon>
                <Material.ListItemText
                  sx={{
                    display: {
                      xs: mobileOpen ? "block" : "none",
                      sm: openDrawer ? "block" : "none",
                    },
                  }}
                >
                  Management
                </Material.ListItemText>
                {openManagement ? (
                  <ExpandLessIcon
                    sx={{ color: openManagement ? "white" : "#1976D2" }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ color: openManagement ? "white" : "#1976D2" }}
                  />
                )}
              </Material.ListItemButton>
            </Material.ListItem>
            <Material.Collapse
              in={openManagement && (mobileOpen || hovered)}
              timeout="auto"
              unmountOnExit
            >
              <Material.List component="div" disablePadding>
                {[
                  {
                    text: "Skills",
                    icon: <PeopleAltIcon sx={{ color: "#1976D2" }} />,
                    href: "skills",
                  },
                  {
                    text: "Employment Status",
                    icon: <WorkIcon sx={{ color: "#1976D2" }} />,
                    href: "employmentstatus",
                  },
                  {
                    text: "DOLE Program",
                    icon: <AssignmentIcon sx={{ color: "#1976D2" }} />,
                    href: "doleprogrammanagement",
                  },
                  {
                    text: "DOLE Program Availments",
                    icon: <SummarizeIcon sx={{ color: "#1976D2" }} />,
                    href: "doleprogram",
                  },
                  // {
                  //   text: "Another Management Here",
                  //   icon: <InfoIcon sx={{ color: "#1976D2" }} />,
                  //   href: "about",
                  // },
                ].map((item, index) => (
                  <Material.ListItem
                    disablePadding
                    key={item.text}
                    sx={{ paddingLeft: 4 }}
                  >
                    <Material.ListItemButton
                      component="a"
                      href={item.href}
                      onClick={() => handleButtonClick(index + 3)} // Offset index for unique identification
                      sx={{
                        "&:hover": {
                          backgroundColor: "#88c3fc",
                          color: "#1976D2",
                        },
                        "&:active": {
                          backgroundColor: "#88c3fc",
                          color: "white",
                        },
                        backgroundColor:
                          activeButton === index + 3
                            ? "#1976D2"
                            : "transparent",
                        color: activeButton === index + 3 ? "white" : "inherit",
                      }}
                    >
                      <Material.ListItemIcon>{item.icon}</Material.ListItemIcon>
                      <Material.ListItemText
                        sx={{
                          display: {
                            xs: mobileOpen ? "block" : "none",
                            sm: openDrawer ? "block" : "none",
                          },
                        }}
                      >
                        {item.text}
                      </Material.ListItemText>
                    </Material.ListItemButton>
                  </Material.ListItem>
                ))}
              </Material.List>
            </Material.Collapse>
          </Material.List>
        ): ("")}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const HideModalChangePassword = () => {
    setmodalVisibleChangePassword(false);
  };

  const ShowModalChangePassword = () => {
    setmodalVisibleChangePassword(true);
    setOpen(false);
  };

  const onClickChangePassword = (e) => {
    ShowModalChangePassword();
  };

  const theme = useTheme();

  const Icons = Material.styled(Material.Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      alignItems: "center",
    },
  }));

  return (
    <Material.Box sx={{ display: "flex" }}>
      <Material.CssBaseline />
      <Material.AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: "linear-gradient(to right, #004AAD, #5DE0E6)",
        }}
      >
        <StyledToolbar>
          <Material.Toolbar>
            <Material.IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 1, display: { sm: "none" } }}
            >
              <MenuIcon />
            </Material.IconButton>
            <Material.Avatar
              src={PGBhLogo}
              alt="PGBh Logo"
              sx={{
                marginRight: 1,
                display: {
                  xs: "block",
                  sm: "block",
                  md: "block",
                  lg: "block",
                },
              }}
            />
            <Material.Box>
              <Material.Typography
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    fontFamily: "Montserrat, sans-serif",
                  },
                }}
              >
                <b>BOHOL SKILLS REGISTRATION SYSTEM</b>
              </Material.Typography>
              <Material.Typography
                sx={{
                  marginTop: 0,
                  display: {
                    xs: "block",
                    sm: "block",
                    color: "#FFFFFF",
                    fontSize: "0.75rem",
                  },
                }}
              >
                <b>BEPO-PESO</b>
              </Material.Typography>
            </Material.Box>
          </Material.Toolbar>
          <Icons>
            <Material.Typography
              variant="span"
              sx={{ display: { xs: "none", sm: "none", md: "block" } }}
            >
              {user_name}
            </Material.Typography>
            <AccountCircleIcon
              sx={{
                width: 30,
                height: 30,
                cursor: "pointer",
                color: "white", // Change color to green
              }}
              onClick={(e) => setOpen(true)}
            />
          </Icons>
        </StyledToolbar>
        <Material.Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          open={open}
          onClose={(e) => setOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Material.MenuItem component="a" href="myaccount">
            My account
          </Material.MenuItem>
          <Material.MenuItem onClick={onClickChangePassword}>
            Change Password
          </Material.MenuItem>
          <Material.MenuItem
            href="/login"
            onClick={() => {
              ForceLogout();
            }}
          >
            Logout
          </Material.MenuItem>
        </Material.Menu>
        <ChangePasswordModal
          ModalVisible={modalVisibleChangePassword}
          HideModal={HideModalChangePassword}
        />
      </Material.AppBar>
      <Material.Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Material.Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: 0,
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Material.Drawer>
        <Material.Drawer
          variant="permanent"
          open={openDrawer}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: openDrawer ? drawerWidth : "60px",
              overflowX: "hidden",
              transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            },
          }}
        >
          {drawer}
        </Material.Drawer>
      </Material.Box>

      <Material.Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
          transition: "margin-left 0.2s ease", // Smooth transition for margin change
          marginLeft: openDrawer ? 10 : -10, // Adjust margin when drawer is open
          marginTop: 8,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* Main content area */}
      </Material.Box>
    </Material.Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default Navbar;
