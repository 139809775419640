import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Checkbox,
  TextField,
  Typography,
  Modal,
  Autocomplete,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
} from "@mui/material";
import { displayAlert } from "../../common/Alert";
import DoleProgramService, {
  Edit,
  Save,
} from "../../../services/DoleProgramService";
import LoadingSpinner from "../../common/LoadingSpinner";

const defaultStateData = {
  profile_id: "",
  fullname: "",
  address: "",
  programs: [], // AVAILMENT PROGRAMS OF A PROFILE
};

const NUMBER_OF_AVAILMENTS = [1, 2, 3, 4];

function DoleProgramAdd({
  SetSelectedRow,
  SelectedRow,
  ModalVisible,
  HideModal,
  RefreshUser,
  fetchDoleAvailmentsSummary,
}) {
  let isEdit = Object.keys(SelectedRow).length > 0;
  const [editMode, setEditMode] = useState(isEdit);
  const [profiles, setProfiles] = useState([]);
  const [usersInput, setUsersInput] = useState(defaultStateData);
  const [dynamicDolePrograms, setDynamicDolePrograms] = useState([]);
  const [selectedDolePrograms, setSelectedDolePrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const hasFetchedDolePrograms = useRef(false);

  useEffect(() => {
    if (!editMode) {
      setUsersInput((prevState) => {
        return {
          ...prevState,
          programs: selectedDolePrograms.map((program) => program),
        };
      });
    }
  }, [selectedDolePrograms, editMode]);

  useEffect(() => {
    setEditMode(isEdit);

    if (editMode) {
      setSelectedDolePrograms([
        {
          doleProgramId: SelectedRow.dole_program_id,
          program: SelectedRow.dole_program,
          availments: [
            {
              order: parseInt(SelectedRow.availment),
              periodFrom: new Date(SelectedRow.date_from)
                .toISOString()
                .split("T")[0],
              periodTo: new Date(SelectedRow.date_to)
                .toISOString()
                .split("T")[0],
              programId: SelectedRow.program_id,
            },
          ],
        },
      ]);
    }

    if (ModalVisible) {
      const initialState = editMode
        ? {
            fullname: SelectedRow.name,
            address: SelectedRow.address,
          }
        : defaultStateData;
      setUsersInput(initialState);
    }
  }, [ModalVisible, SelectedRow, editMode]);

  useEffect(() => {
    fetchProfiles();
    if (!hasFetchedDolePrograms.current) {
      fetchDoleProgramsAvailment();
      hasFetchedDolePrograms.current = true;
    }
  }, []);

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const data = await DoleProgramService.getDoleProgram();
      setProfiles(data);
      setLoading(false);
    } catch (error) {
      console.error("$$Error fetching profiles:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  const fetchDoleProgramsAvailment = async () => {
    try {
      const data = await DoleProgramService.getDynamicDolePrograms();
      setDynamicDolePrograms(data);
      // data.map((e, i) => console.log(i, e));
    } catch (error) {
      console.error("$$Error fetching Dole Programs details:", error);
      setError(error.message);
    }
  };

  const onAutocompleteChange = (event, newValue) => {
    setUsersInput((prevState) => ({
      ...prevState,
      profile_id: newValue ? newValue.id : "",
      fullname: newValue ? newValue.fullname : "",
      address: newValue ? newValue.address : "",
    }));
  };

  const onInputChange = (event, newInputValue) => {
    setUsersInput((prevState) => ({
      ...prevState,
      fullname: newInputValue,
    }));
  };

  const [errors, setErrors] = useState({
    fullname: false,
    address: false,
    programs: false,
    availments: [],
  });

  const onSave = () => {
    if (editMode) {
      const availmentIndex = selectedDolePrograms[0].availments.length > 1 ? 1 : 0;
      const data = {
        id: SelectedRow.id,
        date_from: selectedDolePrograms[0].availments[availmentIndex].periodFrom,
        date_to: selectedDolePrograms[0].availments[availmentIndex].periodTo,
        program_id: selectedDolePrograms[0].availments[availmentIndex].programId,
      };

      HideModal();
      setLoading(true);
      Edit(data)
        .then((res) => {
          if (res.error) {
            displayAlert({
              message: res.message,
              severity: "error",
            });
          } else {
            displayAlert({
              message: `Dole Program edited successfully. ${res.message}!`,
              severity: "success",
            });
            fetchDoleAvailmentsSummary();
            RefreshUser();
            resetState();
            HideModal();
          }
        })
        .catch((err) => {
          displayAlert({
            message: err,
            severity: "error",
          });
        })
        .finally(() => setLoading(false));
      resetState();

      return;
    }

    let hasAvailments;
    if (usersInput.programs.length > 0) {
      hasAvailments = usersInput.programs.some(
        (program) => program.availments.length > 0
      );
    }

    let availmentsAreFilled;
    if (hasAvailments) {
      availmentsAreFilled = usersInput.programs.every(
        (program) =>
          program.doleProgramId !== "" &&
          program.program !== "" &&
          program.availments.every(
            (availment) =>
              availment.order !== "" &&
              availment.periodFrom !== "" &&
              availment.periodTo !== "" &&
              availment.programId !== ""
          )
      );
    }

    if (
      usersInput.fullname &&
      usersInput.address &&
      usersInput.programs.length > 0 &&
      availmentsAreFilled
    ) {
      HideModal();
      setLoading(true);
      Save(usersInput)
        .then((res) => {
          if (res.error) {
            displayAlert({
              message: res.message,
              severity: "error",
            });
          } else {
            displayAlert({
              message: `Dole Program saved successfully. ${res.message}!`,
              severity: "success",
            });
            fetchDoleAvailmentsSummary();
            RefreshUser();
            resetState();
            HideModal();
          }
        })
        .catch((err) => {
          displayAlert({
            message: err,
            severity: "error",
          });
        })
        .finally(() => setLoading(false));
      resetState();
    } else {
      displayAlert({
        message: "Please fill all the required fields!",
        severity: "warning",
      });
    }
  };

  const onCancel = () => {
    resetState();
    HideModal();
  };

  const resetState = () => {
    setUsersInput(defaultStateData);
    setSelectedDolePrograms([]);
    setEditMode(false);
    SetSelectedRow({});
  };

  return (
    <div>
      <Modal
        open={ModalVisible}
        onClose={HideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Box
          width={900}
          maxHeight="80vh"
          bgcolor={"background.default"}
          color={"text.primary"}
          p={3}
          borderRadius={3}
          paddingBottom={10}
          sx={{ overflowY: "auto" }}
        >
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
            {editMode ? "EDIT DOLE PROGRAM" : "ADD NEW DOLE PROGRAM"}
          </Typography>
          <Box sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  disabled={editMode}
                  fullWidth
                  freeSolo
                  options={profiles}
                  getOptionLabel={(option) => option.fullname}
                  value={
                    profiles.find(
                      (profile) => profile.fullname === usersInput.fullname
                    ) || { fullname: usersInput.fullname }
                  }
                  onChange={onAutocompleteChange}
                  onInputChange={onInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Full Name"
                      required
                      error={errors.fullname}
                      helperText={
                        errors.fullname ? "Full Name is required" : ""
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Address"
                  name="address"
                  value={usersInput.address}
                  disabled={editMode}
                  required
                  error={errors.address}
                  helperText={errors.address ? "Address is required" : ""}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {dynamicDolePrograms.map((program) => (
                <DoleProgramCheckBox
                  key={program.id}
                  editMode={editMode}
                  program={program}
                  SelectedRow={SelectedRow}
                  selectedDolePrograms={selectedDolePrograms}
                  setSelectedDolePrograms={setSelectedDolePrograms}
                />
              ))}
            </Grid>
            <Stack>
              {selectedDolePrograms.length > 0 &&
                selectedDolePrograms.map((program) => (
                  <Box
                    sx={{ mt: 2 }}
                    key={`${program.doleProgramId}-${program.program}`}
                  >
                    {editMode ? (
                      <Typography variant="h6">
                        History of{" "}
                        {SelectedRow.program_name?.toUpperCase() || ""}{" "}
                        Availment
                      </Typography>
                    ) : (
                      <Typography variant="h6">
                        History of {program.program?.toUpperCase() || ""}{" "}
                        Availment
                      </Typography>
                    )}

                    <Table
                      size="small"
                      aria-label={`${program.program}-availment`}
                    >
                      <TableBody>
                        {NUMBER_OF_AVAILMENTS.map((_, i) => (
                          <DoleProgramAvailments
                            key={i}
                            index={i}
                            SelectedRow={SelectedRow}
                            program={program}
                            editMode={editMode}
                            selectedDolePrograms={selectedDolePrograms}
                            setSelectedDolePrograms={setSelectedDolePrograms}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                ))}
            </Stack>
            <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSave}
                sx={{ mr: 2 }}
              >
                Save
              </Button>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
          {loading && <LoadingSpinner />}
        </Box>
      </Modal>
    </div>
  );
}

export default DoleProgramAdd;

function DoleProgramCheckBox({
  editMode,
  program,
  selectedDolePrograms,
  setSelectedDolePrograms,
}) {
  // console.log("DOLE PROGRAM CHECKBOX", program.id, program.program);

  const [isChecked, setIsChecked] = useState(false);

  if (editMode) return null;

  // console.log("DOLE PROGRAM CHECKBOX", selectedDolePrograms);
  return (
    <Grid item xs={3}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={() => {
              if (isChecked) {
                setSelectedDolePrograms(
                  selectedDolePrograms.filter(
                    (selectedProgram) =>
                      selectedProgram.doleProgramId !== program.id
                  )
                );
              } else {
                setSelectedDolePrograms([
                  ...selectedDolePrograms,
                  {
                    doleProgramId: program.id,
                    program: program.program,
                    availments: [],
                  },
                ]);
              }
              setIsChecked(!isChecked);
            }}
          />
        }
        label={program.program}
        disabled={editMode}
      />
    </Grid>
  );
}

function DoleProgramAvailments({
  index,
  program,
  editMode,
  SelectedRow,
  setSelectedDolePrograms,
}) {
  // console.log("selected", SelectedRow);
  const isJobStartProgram = editMode
    ? SelectedRow?.program_name === "JOBSTART"
    : program?.program === "JOBSTART";

  function getOrdinalSuffix(n) {
    const currentYear = new Date().getFullYear();
    if (isJobStartProgram) {
      if (n === 1) return `${currentYear} Wave 1 Roll-Out`;
      if (n === 2) return `${currentYear} Wave 2 Roll-Out`;
    } else {
      if (n === 1) return "1st";
      if (n === 2) return "2nd";
      if (n === 3) return "3rd";
      if (n === 4) return "4th";
    }
    return `${n}th`;
  }

  const formatDate = (date) => {
    // console.log("format", date);
    if (!date) return "";
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, "0");
    const day = `${d.getDate()}`.padStart(2, "0");
    return [d.getFullYear(), month, day].join("-");
  };
  const getInitialState = () => {
    if (
      editMode &&
      SelectedRow &&
      SelectedRow.availment === getOrdinalSuffix(index + 1)
    ) {
      return {
        order: SelectedRow.availment || index + 1,
        periodFrom: formatDate(SelectedRow.date_from) || "",
        periodTo: formatDate(SelectedRow.date_to) || "",
        programId: SelectedRow.program_id || "",
      };
    } else {
      return {
        order: index + 1,
        periodFrom: "",
        periodTo: "",
        programId: "",
      };
    }
  };

  const [currentAvailmentInput, setCurrentAvailmentInput] =
    useState(getInitialState);
  const [isChecked, setIsChecked] = useState(
    editMode &&
      !!SelectedRow &&
      SelectedRow.availment === getOrdinalSuffix(index + 1)
  );

  useEffect(() => {
    if (
      editMode &&
      SelectedRow &&
      SelectedRow.availment === getOrdinalSuffix(index + 1)
    ) {
      setIsChecked(true);
      setCurrentAvailmentInput({
        order: SelectedRow.availment || index + 1,
        periodFrom: formatDate(SelectedRow.date_from) || "",
        periodTo: formatDate(SelectedRow.date_to) || "",
        programId: SelectedRow.program_id || "",
      });
    }
  }, [editMode, index, SelectedRow]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setCurrentAvailmentInput((prev) => ({
        ...prev,
        order: index + 1,
      }));
    }
  };

  const handleInputChange = (field, value) => {
    // console.log("valuebInput", value);
    setCurrentAvailmentInput((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  useEffect(() => {
    // console.log("$$123", currentAvailmentInput);
  });

  useEffect(() => {
    setSelectedDolePrograms((prevSelectedDolePrograms) => {
      return prevSelectedDolePrograms.map((p) => {
        if (p.doleProgramId === program.doleProgramId) {
          const filteredAvailment = p.availments.filter(
            (fa) => fa.order !== currentAvailmentInput.order
          );
          return {
            ...p,
            availments: isChecked
              ? [...filteredAvailment, currentAvailmentInput]
              : filteredAvailment,
          };
        }
        return p;
      });
    });
  }, [currentAvailmentInput, isChecked, program.doleProgramId]);

  return (
    <TableRow key={index}>
      {isJobStartProgram && index < 2 && (
        <>
          <TableCell>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  disabled={editMode}
                  onChange={handleCheckboxChange}
                  name={`${program.program}_Wave_${
                    index + 1
                  }_Roll-Out ${new Date().getFullYear()}`}
                />
              }
              label={getOrdinalSuffix(index + 1)}
            />
          </TableCell>
          <TableCell>
            <TextField
              required
              label="Availment Date"
              type="date"
              variant="standard"
              name={`period_from_${index}`}
              value={currentAvailmentInput.periodFrom || ""}
              onChange={(e) => handleInputChange("periodFrom", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              required
              label="Period To"
              type="date"
              variant="standard"
              name={`period_to_${index}`}
              value={currentAvailmentInput.periodTo || ""}
              onChange={(e) => handleInputChange("periodTo", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              variant="standard"
              required
              label={`${
                editMode
                  ? SelectedRow.program_name
                  : program.program?.toUpperCase() || ""
              } ID No.`}
              name={`${
                editMode ? SelectedRow.program_name : program.program
              }_id_no_${index}`}
              value={currentAvailmentInput.programId || ""}
              onChange={(e) => handleInputChange("programId", e.target.value)}
              disabled={!isChecked}
            />
          </TableCell>
        </>
      )}
      {/* For other programs */}
      {!isJobStartProgram && (
        <>
          <TableCell>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  disabled={editMode}
                  onChange={handleCheckboxChange}
                  name={`${program.program}_availment_${index + 1}`}
                />
              }
              label={getOrdinalSuffix(index + 1)}
            />
          </TableCell>
          <TableCell>
            <TextField
              label="Period From"
              required
              type="date"
              variant="standard"
              name={`period_from_${index}`}
              value={currentAvailmentInput.periodFrom || ""}
              onChange={(e) => handleInputChange("periodFrom", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              label="Period To"
              required
              type="date"
              variant="standard"
              name={`period_to_${index}`}
              value={currentAvailmentInput.periodTo || ""}
              onChange={(e) => handleInputChange("periodTo", e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={!isChecked}
            />
          </TableCell>
          <TableCell>
            <TextField
              variant="standard"
              required
              label={`${
                editMode
                  ? SelectedRow.program_name
                  : program.program?.toUpperCase() || ""
              } ID No.`}
              name={`${
                editMode ? SelectedRow.program_name : program.program
              }_id_no_${index}`}
              value={currentAvailmentInput.programId || ""}
              onChange={(e) => handleInputChange("programId", e.target.value)}
              disabled={!isChecked}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
