import axios from "axios";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import * as Material from "@mui/material";
import dayjs from "dayjs";
import "./Profile.Form.Style.css";

import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";

import PGBHLogo from "../../assets/PGBh.png";
import BagongPilipinas from "../../assets/BAGONG-PILIPINAS-LOGO.png";
import BEPO from "../../assets/bepo.png";
import PESO from "../../assets/peso.png";
import MOTTO from "../../assets/BEPO_motto.png";

const tableStyles = {
  width: "50%",
  border: "1px solid black",
  borderCollapse: "collapse",
};

const cellStyles = {
  fontWeight: "bold",
  textAlign: "center",
  border: "1px solid black",
};

const bodyCellStyles = {
  textAlign: "center",
  border: "1px solid black",
};

const columnHeaders = [
  "NO",
  "NAME",
  "OCCUPATION/SKILL",
  "AGE",
  "SEX",
  "CIVIL",
  "RELIGION",
  "EDUCATIONAL",
  "COURSE",
  "VOCATIONAL COURSE",
  "YRS. OF WORK",
  "EMPLOYMENT STATUS",
  "ADDRESS",
  "CONTACT",
  "EMAIL",
  "LOCAL/OVERSEAS",
  "REMARKS",
];

function ProfileForm() {
  const location = useLocation();
  const user_data = useSelector((state) => state.user);
  const componentRef = useRef(null);
  const allProfiles = location.state?.profiles || []; // Retrieve passed state
  const startDate = location.state?.date_from || "";
  const endDate = location.state?.date_to || "";

  const formattedStartDate = startDate
    ? dayjs(startDate.$d).format("MMMM DD, YYYY")
    : "";
  const formattedEndDate = endDate
    ? dayjs(endDate.$d).format("MMMM DD, YYYY")
    : "";

  const handleDownloadExcel = () => {
    // Define the table data
    const data = flatProfiles.map((applicantArray, index) => {
      const applicant = Array.isArray(applicantArray)
        ? applicantArray[0]
        : applicantArray;

      return {
        "#": index + 1,
        Name: `${applicant.lastname}, ${applicant.firstname} ${
          applicant.middlename || ""
        } ${applicant.suffix || ""}`.trim(),
        Skills: applicant.other_skills
          ? applicant.other_skills
              .split(",")
              .map(
                (skill) =>
                  skill.trim().charAt(0).toUpperCase() + skill.trim().slice(1)
              )
              .join(", ")
          : "N/A",
        Age: applicant.age || "N/A",
        Gender: applicant.gender || "N/A",
        "Civil Status": applicant.civil_status || "N/A",
        Religion: applicant.religion || "N/A",
        "Education Status": applicant.educ_status || "N/A",
        "College Course": applicant.college_course || "N/A",
        "Vocational Course": applicant.voc_course || "N/A",
        "Work Experience": applicant.work_experience
          ? (applicant.work_experience.match(/No\. of Months:\s*(\d+)/)?.[1] ||
              "N/A") + " mos"
          : "N/A",
        "Employment Status": applicant.employment_status || "N/A",
        Address: applicant.address || "N/A",
        Mobile: applicant.mobile || "N/A",
        Email: applicant.email || "N/A",
        "OFW Status": applicant.is_ofw > 0 ? "Overseas" : "Local",
        "DOLE Program": applicant.dole_program
          ? applicant.dole_program
              .replace(/\s*[\[\(][^\]\)]*[\]\)]/g, "")
              .trim()
          : "N/A",
      };
    });

    // Create a worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Applicants Registered");

    // Write and save Excel file
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, `Applicants Registered${fileExtension}`);
  };

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "Applicants Registered",
  });

  const flatProfiles = allProfiles.flat();

  return (
    // <div>
    //   <h1>Profile Form</h1>
    //   <pre>{JSON.stringify(allProfiles, null, 2)}</pre>
    // </div>
    <Material.Box
      flex={4}
      height="90vh"
      sx={{ maxWidth: "90%", margin: "0 auto", overflow: "hidden" }}
    >
      <Material.Box
        paddingLeft={{ xs: 2, sm: 3, md: 3 }}
        paddingTop={{ xs: 1, sm: 2, md: 2 }}
      >
        <Material.Grid
          container
          spacing={2}
          sx={{ padding: 2, justifyContent: "center", maxWidth: "100vw" }} // Centering the content
        >
          <Material.Grid
            item
            xs={12}
            md={12}
            height="calc(100vh - 200px)"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Material.Card
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(3px)",
                borderRadius: "15px",
                height: "100%",
                // width: "100%",
                // maxWidth: "100vw",
                position: "relative",
                padding: 3,
                overflow: "auto",
              }}
            >
              <Material.CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  height: "100%",
                }}
              >
                {/* Title Section */}
                <Material.Breadcrumbs aria-label="breadcrumb">
                  <Material.Link
                    underline="hover"
                    sx={{ display: "flex", alignItems: "center" }}
                    color="inherit"
                    href="/profiles"
                  >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    Profiles
                  </Material.Link>
                  <Material.Typography
                    sx={{
                      color: "primary.main",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocalPrintshopRoundedIcon
                      sx={{ mr: 0.5 }}
                      fontSize="inherit"
                    />
                    Print Form
                  </Material.Typography>
                </Material.Breadcrumbs>

                {/* Buttons */}
                <Material.Box
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  {/* Download Button */}
                  <Material.Button
                    variant="outlined"
                    startIcon={<FileDownloadRoundedIcon />}
                    onClick={() => handleDownloadExcel()}
                  >
                    Download
                  </Material.Button>

                  {/* Print Button */}
                  <Material.Button
                    variant="outlined"
                    startIcon={<LocalPrintshopRoundedIcon />}
                    onClick={() => handlePrint()}
                  >
                    Print
                  </Material.Button>
                </Material.Box>

                {/* Search Fields */}
                <Material.Box
                  ref={componentRef}
                  sx={{
                    width: "80vw",
                    height: "80vh",
                    marginTop: 8,
                    textAlign: "center",
                    "@media print": {
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100vw", // Full page width
                      margin: "auto", // Centers horizontally
                      marginTop: 2,
                      transform: "scale(0.9)", // Adjust zoom level (0.8 = 80% of original size)
                    },
                  }}
                >
                  {/* Header with Logos */}
                  <Material.Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      px: 5,
                    }}
                  >
                    {/* Left Logos */}
                    <Material.Box sx={{ display: "flex", gap: 12, ml: 14 }}>
                      <Material.Box
                        component="img"
                        src={BagongPilipinas}
                        sx={{ height: 120 }}
                      />
                      <Material.Box
                        component="img"
                        src={PGBHLogo}
                        sx={{ height: 110 }}
                      />
                    </Material.Box>

                    {/* Centered Title */}
                    <Material.Box>
                      <Material.Typography variant="body1">
                        Republic of the Philippines
                      </Material.Typography>
                      <Material.Typography
                        variant="h6"
                        sx={{ fontWeight: "bold" }}
                      >
                        PROVINCE OF BOHOL
                      </Material.Typography>
                      <Material.Typography variant="body1">
                        City of Tagbilaran
                      </Material.Typography>
                      <Material.Box sx={{ mt: 2 }}>
                        <Material.Typography
                          variant="body1"
                          sx={{ fontWeight: "bold" }}
                        >
                          OFFICE OF THE GOVERNOR
                        </Material.Typography>
                        <Material.Typography
                          variant="body1"
                          sx={{ fontWeight: "bold" }}
                        >
                          BOHOL EMPLOYMENT & PLACEMENT OFFICE
                        </Material.Typography>
                      </Material.Box>
                    </Material.Box>

                    {/* Right Logos */}
                    <Material.Box sx={{ display: "flex", gap: 1 }}>
                      <Material.Box
                        component="img"
                        src={BEPO}
                        sx={{ height: 120 }}
                      />
                      <Material.Box
                        component="img"
                        src={PESO}
                        sx={{ height: 120 }}
                      />
                      <Material.Box
                        component="img"
                        src={MOTTO}
                        sx={{ height: 140 }}
                      />
                    </Material.Box>
                  </Material.Box>

                  {/* Report Title */}
                  <Material.Typography
                    variant="h6"
                    sx={{ marginTop: 2, fontWeight: "bold" }}
                  >
                    Applicants Registered
                  </Material.Typography>
                  <Material.Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                  >
                    {formattedStartDate && formattedEndDate
                      ? `${formattedStartDate} to ${formattedEndDate}`
                      : ""}
                  </Material.Typography>

                  {/* Table */}
                  <Material.TableContainer
                    sx={{
                      marginTop: 4,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Material.Table sx={tableStyles}>
                      <Material.TableHead>
                        <Material.TableRow>
                          {columnHeaders.map((header, index) => (
                            <Material.TableCell key={index} sx={cellStyles}>
                              {header}
                            </Material.TableCell>
                          ))}
                        </Material.TableRow>
                      </Material.TableHead>
                      <Material.TableBody>
                        {flatProfiles.length > 0 ? (
                          flatProfiles.map((applicantArray, index) => {
                            console.log("$$applicantArray", applicantArray);
                            const applicant = Array.isArray(applicantArray)
                              ? applicantArray[0]
                              : applicantArray; // Ensure it's an object

                            return (
                              <Material.TableRow key={applicant.id}>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {index + 1}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {`${applicant.lastname}, ${
                                    applicant.firstname
                                  } ${applicant.middlename} ${
                                    applicant.suffix || ""
                                  }`}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.job_preference
                                    ? (() => {
                                        const occupations =
                                          applicant.job_preference
                                            .split(", ") // Split into key-value pairs
                                            .filter((item) =>
                                              item.startsWith(
                                                "Pref_occupation:"
                                              )
                                            ) // Get only Pref_occupation
                                            .map((item) =>
                                              item
                                                .replace(
                                                  "Pref_occupation: ",
                                                  ""
                                                )
                                                .trim()
                                            ) // Remove label & trim spaces
                                            .filter((item) => item) // Remove empty values
                                            .map(
                                              (item) =>
                                                item.charAt(0).toUpperCase() +
                                                item.slice(1)
                                            ); // Capitalize first letter

                                        return occupations.length
                                          ? occupations.join(", ")
                                          : ""; // Return formatted text or empty string
                                      })()
                                    : ""}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.age}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.gender}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.civil_status}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.religion}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.educ_status}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.college_course || ""}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.voc_course}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.work_experience
                                    ? (applicant.work_experience.match(
                                        /No\. of Months:\s*(\d+)/
                                      )?.[1] || "N/A") + " mos"
                                    : "N/A"}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.employment_status}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.address}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.mobile}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.email}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.is_ofw > 0 ? "Overseas" : "Local"}
                                </Material.TableCell>
                                <Material.TableCell sx={bodyCellStyles}>
                                  {applicant.dole_program
                                    ? applicant.dole_program
                                        .replace(/\s*[\[\(][^\]\)]*[\]\)]/g, "")
                                        .trim()
                                    : ""}
                                </Material.TableCell>
                              </Material.TableRow>
                            );
                          })
                        ) : (
                          <Material.TableRow>
                            <Material.TableCell
                              colSpan={2}
                              sx={{ textAlign: "center" }}
                            >
                              No data available
                            </Material.TableCell>
                          </Material.TableRow>
                        )}
                      </Material.TableBody>
                    </Material.Table>
                  </Material.TableContainer>
                  <Material.Box
                    sx={{
                      width: "100%",
                      textAlign: "right",
                      padding: "40px 0",
                    }}
                  >
                    <Material.Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "8px",
                      }}
                    >
                      <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
                        Printed By:
                      </span>{" "}
                      {user_data.name}
                    </Material.Typography>
                  </Material.Box>
                </Material.Box>
              </Material.CardContent>
            </Material.Card>
          </Material.Grid>
        </Material.Grid>
      </Material.Box>
    </Material.Box>
  );
}

export default ProfileForm;
